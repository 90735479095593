import React, { useEffect, useState } from "react";
import img from "../../assets/Logo.png";
import logo from "../../assets/Logo.png";
import img1 from "../../assets/navcolor.png";
import img2 from "../../assets/nav.png";
import bg1 from "../../assets/bg1.png";
import bg2 from "../../assets/bg2.png";
import bg5 from "../../assets/bg5.png";
import footer from "../../assets/footer.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Navbar from "../../components/common/Navbar";
import axios from "axios";
import { baseUrl } from "../../apiservice";
const Pagefirst = () => {
  const location = useLocation();
  const event = location.state?.event;

  const [showNavbar, setShowNavbar] = useState(false);
  const [summarydata, setsummarydata] = useState(false);

  const fetchTicketsData = async () => {
    const userId = sessionStorage.getItem("id");
    try {
      const response = await axios.post(
        `${baseUrl}booking-summary`,
        {
          event_id: event.id,
          userId,
          is_confirm: "true",
        }
      );
      console.log(response);

      setsummarydata(response.data.data.data);
      console.log(response.data.data.data);
      
    } catch (error) {
      console.error("Error fetching tickets data:", error);
    }
  };

  useEffect(() => {
    fetchTicketsData();
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const token = sessionStorage.getItem("token");

  const [termsAccepted, setTermsAccepted] = useState({
    eventlane: false,
    organizer: false,
  });

  const handleCheckboxChange = (key) => {
    setTermsAccepted((prev) => ({ ...prev, [key]: !prev[key] }));
  };



  // const handlePayment = async () => {
  //   const userId = sessionStorage.getItem("id");
  //   const token = sessionStorage.getItem("token");

  //   try {
      
  //      const {  total } = summarydata;

      
  //     const options = {
  //       key: "rzp_live_XhH0lVTYdwBcI1", 
  //       amount: total* 100,
  //       currency: 'INR',
  //       name: "Eventlane",
  //       payment_capture: 1,
  //       description: `Payment for ${summarydata.event_name}`,
  //       order_id : summarydata.order_id, 
  //       name:summarydata.user_name,
  //       prefill: {
  //         email: summarydata?.email,
  //         contact: summarydata?.mobile,
  //         name: summarydata?.user_name,
  //       },
        
  //       handler: async function (response) {
       
  //         await axios.post(
  //           "http://localhost:5001/api/booking-confirm",
  //           {
  //             order_id: summarydata.order_id,
  //             transaction_id: response.razorpay_order_id,
              
  //           },
  //           { headers: { Authorization: `Bearer ${token}` } }
  //         );
  //         alert("Payment successful!");
          
  //       },
        
  //     };

  //     const rzp = new window.Razorpay(options);
  //     rzp.open();
  //   } catch (error) {
  //     console.error("Error during payment initialization:", error);
  //     alert("Payment failed. Please try again.");
  //   }
  // };

  const loadRazorpayScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => resolve(true);
      script.onerror = () => resolve(false);
      document.body.appendChild(script);
    });
  };

  const navigate  = useNavigate()

  
  const handlePayment = async () => {

    const isScriptLoaded = await loadRazorpayScript();
    console.log('sss',isScriptLoaded);
    
    if (!isScriptLoaded) {
      alert("Razorpay  failed to load. Please try again.");
      navigate('/page-second')
      return;
    }
  
    const userId = sessionStorage.getItem("id");
    const token = sessionStorage.getItem("token");
  
    if (!userId || !token) {
      navigate('/login')
     return;
    }
  
    try {
     
      const { total, event_name, order_id, email, mobile, user_name } = summarydata;
  
      if (!total || !order_id) {
        alert("Invalid payment details!");
        navigate('/page-second')
        return;
      }
    
      
      const options = {
        key: "rzp_live_nM9cOTS66d1xZR", 
        amount: total * 100, 
        currency: "INR",
        name: "Eventlane",
        description: `Payment for ${event_name}`,
        order_id,
        prefill: {
          email,
          contact: mobile,
          name: user_name,
        },
        handler: async (response) => {

          console.log('dddd');
          console.log("Razorpay response:", response);
          if (response.razorpay_payment_id) {
          try {
            await axios.post(
              `${baseUrl}booking-confirm`,
              {
                order_id,
                transaction_id: response.razorpay_payment_id, // Use correct Razorpay response field
              },
              { headers: { Authorization: `Bearer ${token}` } }
            );
            alert("Payment successful!");
            navigate('/my-events')
          } catch (error) {
            console.error("Error confirming booking:", error);
            
            alert("Payment confirmed but booking failed. Contact support.");
            navigate('/page-second')
          }
        } else {
          alert("Payment failed. No transaction ID received.");
          navigate('/page-second')
        }
        },
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      console.error("Error during payment initialization:", error);
      alert("Payment failed. Please try again.");
      navigate('/page-second')
    }
  };
  
  

  const [earlyBirdTickets, setEarlyBirdTickets] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState(0);
  const ticketPrice = 1000;
  const generalPrice = 1500;

  const total = earlyBirdTickets * ticketPrice || generalPrice;

  const handleTicketChange = (e) => {
    setEarlyBirdTickets(parseInt(e.target.value, 10));
  };
  return (
    <div>
      <Navbar />

      <div className=" bg-black text-white flex items-center  bg-cover justify-center" style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}>
      <div className="w-full flex flex-col max-w-5xl mt-[7rem]  rounded-lg  px-6 py-3">
 
 <div className="  text-white flex   justify-center ">
      <div className="w-full max-w-4xl h-[15rem] sm:h-[19rem] rounded-lg sm:p-2">
        
      <div className="flex items-start   space-x-12">
          <img
            src={event.getGalleryDetail[0]['file']}
            alt="Webinar"
            className="w-[8rem] h-[9rem] sm:w-[18rem] sm:h-[16rem] rounded-lg border-3 border-white"
          />
          <div className='flex-col  justify-start h-full'>
            <p className="text-[#cccccc] text-xs sm:text-2xl mb-1">{event?.category ? event.category['name'] : ''}</p>
            <span className=" text-sm sm:text-4xl ">
            {event?.event_name}
            </span>
            <div className="flex flex-col items-start gap-1 sm:mt-4 sm:space-y-4">
            <div className="flex gap-2 items-center space-x-2 text-xs sm:text-2xl text-[#f5167e]">
                <i className="far fa-calendar-alt"></i>
                Sat Oct 05, 09:00 PM
              </div>
              <div className="flex gap-2 items-center space-x-2  text-xs sm:text-2xl text-white">
                <i className="fas fa-map-marker-alt"></i>
                {event?.venue}
              </div>
            </div>
          </div>
        </div>

        
        



      </div>
    </div> 




<div className=" flex items-center justify-center w-full ">
          <div className="flex items-center  sm:mt-8 space-x-6 sm:space-x-3">
            <div className="flex flex-col items-center">
              <div className="w-12 h-12 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-3xl text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-white mt-2">Tickets</p>
            </div>
            <div className="h-1 mb-[2.5rem] w-8 sm:w-36 bg-[#f5167e]"></div>

            <div className="flex flex-col items-center">
              <div className="w-12 h-12  flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
              <i className="fas fa-check text-3xl text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-gray-400 mt-2">Summary</p>
            </div>
            <div className="h-1 mb-[2.5rem] w-8 sm:w-36 bg-gray-600"></div>

            <div className="flex flex-col items-center">
              <div className="w-12 h-12  flex items-center justify-center bg-[#f5167e] rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className=""></i>
              </div>
              <p className="text-sm text-gray-400 mt-2">Payment</p>
            </div>
          </div>
        </div>


    

          <div className="bg-[#262626] text-white py-4 px-4 mt-1 rounded-3xl mb-5 text-xs sm:text-xl">
            {/* 
<div className="text-2xl font-bold mb-4">
          <button className="text-3xl text-white">
            ←
          </button>
          Your Ticket Details</div>
       */}
            <div className="bg-[#262626] text-white p-3 rounded-lg  mx-auto">
              <div className="space-y-4">
                {summarydata &&
                  summarydata.ticket_data.map((tick) => (
                    <div className="flex justify-between mb-4">
                      <div className="flex flex-col">
                        <span>{tick.category}</span>
                        <span className="text-gray-400 text-xs">No Cover</span>
                      </div>
                      <span className="text-2xl">{`Rs. ${tick.price}`}</span>
                    </div>
                  ))}

                {/* <hr className=" border-dotted border-white-00 my-4 " /> */}
                <hr className="border-dotted border-2 text-white border-white my-4 w-full mx-auto" />
                <div className="flex justify-between font-semibold text-2xl">
                  <p>Total</p>
                  <p>{summarydata ? `Rs. ${summarydata.total}` : ``}</p>
                </div>

                <div className="mt-6 space-y-3 text-sm sm:text-lg text-gray-400">
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={termsAccepted.eventlane}
                      onChange={() => handleCheckboxChange("eventlane")}
                      className="accent-[#f5167e] w-5 h-5"
                    />
                    I agree to Eventlane's Private Terms and Conditions , refund
                    policy and Privacy Policy.
                  </label>
                  <label className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      checked={termsAccepted.organizer}
                      onChange={() => handleCheckboxChange("organizer")}
                      className="accent-[#f5167e] w-5 h-5"
                    />
                    I agree to Event Organizer's Terms and Conditions , refund
                    policy and Privacy Policy.
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="relative ">
      <img src={bg5} className='absolute -top-20 h-64 w-[100vw] z-0'/>
      
      <div className="relative h-[35rem] z-10">
        
        <img
          src={footer}
          className="w-full h-[35rem] object-cover "
          alt="Background"
        />
      </div>
      <div className="absolute top-0 left-0 right-0  z-40  text-white flex flex-col justify-center items-center h-[40rem] w-full">
        <h1 className="text-[2.5rem] md:text-[3.5rem] text-center">
          Ready to Host an Event? 
        </h1>
        <h2 className="text-[1.5rem] md:text-[2rem] text-center">
          Get Your Event Live in Less Than 3 Minutes
        </h2>
        <Link to='/business'  className="block no-underline">
        <button className="z-40 text-[#f5167e] bg-white rounded-[3rem] mt-12 py-3 px-16 md:py-3 md:px-24">
          List Event
        </button>
        </Link>
      </div>


      
      <footer className="bg-[#262626] text-white py-8 md:py-16">

        
  <div className=" mx-auto h-[105vh] md:h-[47vh] ">
    
    <div className="flex-wrap md:flex md:flex-nowrap   w-[90%]  mx-auto ">

    <div className="w-full md:w-1/3 flex flex-col items-start  ">
          <div className="text-2xl font-bold mb-1 sm:mb-4 ">
            <img src={logo} alt="Eventlane Logo" className="w-3/4  md:mx-0" />
          </div>
          <p className="text-sm w-[90%] ">
          Eventlane is your one-stop platform for discovering, planning, and attending events that matter to you. We connect people with experiences they'll love!
          </p>
          <div className="flex justify-center md:justify-start mt-1 sm:mt-4 text-xl md:text-3xl space-x-4">
            <a href="https://www.facebook.com/eventlaneapp" target='blank' className="text-white ">
              <i className="fab fa-facebook" ></i>
            </a>
            <a href="https://www.instagram.com/eventlaneapp/?igsh=MWRzcWNtODRna2p5ag%3D%3D#" target='blank' className="text-white ">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/eventlane/" target='blank' className="text-white ">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
    
      <div className="flex gap-[4rem] w-full md:w-1/3">
        
        
        
        <div className=" w-1/2 flex-col justify-end items-end">
          <h3 className="text-lg font-semibold mb-4 text-start">Customers & Organisers</h3>
          <h4 className="space-y-2 text-sm"></h4>
            <div className='flex-col gap-2'>
            <p>Explore and Discover</p>
            <p>Create and Sell</p>
            <p>Manage and Invite</p>
            <p>Engage and Inspire</p>
            <p>Join and Enjoy</p>
          
            </div>
        </div>

        <div className=" w-1/2 flex-col text-start justify-center items-center">
          <h3 className="text-lg font-semibold mb-4 text-start">Eventlane</h3>
          <h4 className="space-y-2 text-sm"> </h4>
            <p>For Business</p>
            <p>Blog</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
         
        </div>
      </div>

      
      <div className="flex  gap-[4rem] w-full md:w-1/3">
        
        

        
        <div className="w-full flex flex-col items-start">
          <h3 className="text-lg font-semibold mb-4">Never Miss an Update!</h3>
          <p className="text-sm mb-4">
          Sign up for our newsletter and be the first to know about the latest events and concerts.

          </p>
          <div className="flex items-center relative w-full">
            <div className="flex items-center border-2 border-[#f5167e] bg-white rounded-[2rem] w-full px-4 py-2">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                className="w-full bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-2"
              />
            </div>
            <button className="absolute right-2 px-4 py-2 bg-[#f5167e] rounded-[2rem] text-white ">
              Subscribe now
            </button>
          </div>
        </div>
      </div>
    </div>

   
    
  </div>
  <div className="w-[85%] mx-auto border-t border-gray-600 pt-6 text-center text-sm text-gray-400">
      Copyright © 2024 Softtura. All rights reserved.
    </div> 
</footer>

    </div>

    <div className="bg-[#262626] text-white p-2 flex justify-between mx-auto max-w-screen-full  fixed bottom-0 z-40 w-full">
  <div className='flex justify-around w-full'>
  <div className="text-right flex flex-col items-start">
    <p className="text-xl   mt-1  ">Total</p>
    <p className="text-2xl font-semibold">
              {summarydata ? `Rs. ${summarydata.total}` : ``}
            </p>
          </div>

          <div>
            <Link
              to=''
              className="block no-underline"
              state={{ event: event }}
              onClick={handlePayment}
            >
              <button
                disabled={!termsAccepted.eventlane && !termsAccepted.organizer}
                className={`bg-[#f5167e] text-2xl  text-white transition-colors mt-[.90rem] py-[1rem] px-16 rounded-[2rem] font-medium `}
              >
                Pay Now
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pagefirst;
