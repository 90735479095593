import React, { useEffect, useState } from "react";
import img from "../../assets/google.png";
import img1 from "../../assets/bgshado2.png";
import img2 from "../../assets/bg.png";
import mobile1 from "../../assets/mobile1.png";
import google from "../../assets/google.png";
import { Link } from 'react-router-dom';
import event from "../../../src/assets/event.png";
import art from "../../../src/assets/art.png";
import work from "../../../src/assets/work.png";
import comady from "../../../src/assets/comady.png";
import music from "../../../src/assets/music.png";
import danse from "../../../src/assets/danse.png";
const Thirdpage = () => {
  const testimonials = [
    {
      id: 1,
      text: "I’ve been using this web hosting service for over a year and I’m really impressed with the uptime and support. The website has never gone down and the customer service is always quick to help with any issues I have. Highly recommend!",
      name: "Jane Smith",
      role: "Freelance Designer",
      rating: 5,
      url: "https://s3-alpha-sig.figma.com/img/e6ae/78a4/1a2a749a92b4bf58b305105c2250b00d?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fUuYBNhp-gsyVEtVN~1fgaVrP5VTAkvGEcny~BIrwsWtSzAsxA74h2vRSEHcO6w8vabMu3AYZ-LXip8YGDZ9LUsQXIWdq9ud3Q~Ozt2f0Z1AIhEJa9IduLPE30~agS6HP-Dpz~~tXetYStIZRbjAZVhGNYP9EB~GKsZNvGtpGARBJBkgVihPdRcrcQ5kk7hC9aBov6Jb8giQ55ryVyUnkHKABUZrAPYFgm2xeivKDqRpPtzafDwHd17lSjYq~xDtJEyXlJSZTqD3Oofxrr9~P3Hmg0~F0032w1CNEqu3GZ11Kcf3h5rCijk5gRuhdne8Ynk3s4BuYnhkXDHwZd3lxQ__",
    },
    {
      id: 2,
      text: "I’ve been using this web hosting service for a few months now and overall it’s been fine. The uptime has been good and I haven’t had any major issues. The pricing is also reasonable. Nothing particularly stands out as exceptional, but it gets the job done.",
      name: "Tom Williams",
      role: "Software Developer",
      rating: 4,
      url: "http://s3-alpha-sig.figma.com/img/759a/11f4/32b423df11345cd69ffc23f5036e1f7e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=VaLEN7MBB0KxOX25-PeB4Q8newZx2pFxPmsWnkpwO~VpsdQX2feRIdaUsXJ1WkXJjZRhWbmyyyr-OW1uOiElXk5cLxcjypfWjzHSaSr33geJFQ4aMsp0gq7uhpc7c38F6rl91Nfum8hqx0asCAElVkCQPYeCsM-L5CxXrQNQt36G6SIGI1SMYQs04YStybnVZRWFzoxbdh5JPCiKDVGpTraeFVHa09wiLv4aURO24locqvTCfVsYgjCwbEgqeQm08G6JFJ4ojcFZYSMTtiV-YLsZyYYa1bKmkP1kNCfmSpShAHhqAk7aZqU9eo3p2zo2hgyS5y35pZ3nL3dMmDqC2g__",
    },
    {
      id: 3,
      text: "I’ve been using this web hosting service for a few months and it’s been nothing but problems. My website has gone down multiple times and the customer service has been unresponsive. I would not recommend this company.",
      name: "Michael Brown",
      role: "Online Entrepreneur",
      rating: 2,
      url: "https://s3-alpha-sig.figma.com/img/b607/77c2/dbaaeac8e4446414d2c51a9c95865773?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=aPkksCaALTaFAsDviqTfGRpSPAGvXU863oci0LFaKugzMmM0ysCof~kjtrZPcHmNHLtt~3LAvUONTGhShsn07AygbhokZx7-mUfoLhQB4eQZYKzQmpX-r0i6AllXn4N53Tui5iTnMraIKK~Bi~lAtVlOTNoUCmCVnNrUkMSsPB-whm3-VprAvk6XZKxS5LFfLb9z3eeDd~YMfDGgNh4JSAT9au8wsQxwCYVDESFWJtlQCge54v7beh9fLwga7xj1Z5NV34batS3MvB3xSGude3vg23X6m81SqK1y5Lvuf8~QP7xZ8x1nMrRWNbkq8m8q5OM8Mli0-8K4dj5MdWAcUg__",
    },
  ];


  const im = [{img:"https://s3-alpha-sig.figma.com/img/5578/a0f7/f11fa8bd3ff2c847cb02110d5c0abf8e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Md6hxZpDi2v9wCdNL3OxSnjQyPPURe-vVUOKZduS8lerh-Z~d~SFF7FQVPJcVdq~u7FTd86NfkbzH6aRoaWa14V~sONfVdHNO3Fxs6jsxQ4g~IPfcd0~ly06vPNc5i~Fj-3qn60nTQFJmDCN9vsYAa9V6-xNh5FyQ0dT0vDIpvnOGuPi9XkD~mxWAYcRK1n2-Wigli-s7-G8GEacSGoIWbkV7f-s3naB5khQ-ezhGDALx1Re-CZ46yxGhkgoaBQ~BqGP4ptMxoaxU7WL~835gi4Pp4i~gM2OxwXkALCRUFP6YroKzCIS-7QplPqZ20dGGvHPLTyLKX-Emuc9AAv3Ww__",img1:"https://s3-alpha-sig.figma.com/img/40b7/c131/faedfd7f834c47ba118f3895ffc519cd?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=EotR8M-ZHj4oM2AXjftleLbXhmAPRMGO6VVRiHjfX7iYxHvLYtI5drrtP8UxM0Iv-Q9MA-RBnjtYpGTIi88icf7CBNfDz~RNKm1cVtudwUmJWSHGKt5fFipNVV44WljkDGHm5qy5c9uEQ8V1YtlFzXh1rVH8-G507jnv-aBgMUhwLqzyHTBIziYU7ZkYejlCwxB~ZzTDaXS7~RAUZJHGQ-HNhXAMDc~Snzs1AEK8KvQyxPnYRjZgfNJlZPFipw0tPP9q0dlhDyLBv3RtXj24HSDpv5~HocEIJRtgbAk8w9ww-Q5Bks2eHgiuczT5ivh0jlc~kl4VU-FpP2E3168bJA__"},
    {img:"https://s3-alpha-sig.figma.com/img/7465/4a8f/67369b797c8fb2e96a533fd515fb2939?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=dOIgSZAyOZ3LlGYMnb8c4ltK2gg75RONmtqCcM3qOLhmxfnFewf~VkGPsob4LxQ6gRNjuqdQ9k58-q-4kUnF5p7xtBgE4c0RgRTf0uw0Drkk9iAbD7vyQEvnlGnwwRbuptikbkVS1BWdoD0LETtmvIN6pjsbg8WN1ECUMnRQ07vK~EecMvab-u~RmNzB18DouDrLilahuHXTo2HCXCvPxEDqyL04wFBpbnHg0-9kiWTmSrHwub8qSALeQXAUEFJiaTWV666C9xgIF4wxsmUkY20c962f6nsY2MSIvqExI8ndULFfmqMI1w9GhYFFpKvwTVRYU4Wkrue3GtLxmJ5kaw__",img1:"https://s3-alpha-sig.figma.com/img/f952/fa47/9a54dc57c3bfdd71f8ab78628ef9ac9a?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=OCml1O8qCLZ2IHyNeWFY8NGt~Pbx8yTp113wLunXkApoOWTbOFrVVwQhLteZX1QJlFJ6vOXp5TbYpG7ghh439f7QA~1A4XxwnD~dfgL42Sy9N8QD5EW~Ke8IiKsgdeH3YqcqPQFFCxRZvLqXDKjLRJquWg8CqxFDjFlDGUryUrnpX1jsvHnlFlmZPQZGPNc4o-m4ed7P-d732xvL1PdZYMTSZggUOC3wK9RbgTB8hb4sK39iyObgSc7O3o4fQp4bCABhXsU6tWxXhRR6WNLdy9AlOnc-iYixYLfElddr0nnjGntoB2haiAssBBBXMvBxMqXGQsSWJpSI8TJ3Y7By0A__"},
    {img:"https://s3-alpha-sig.figma.com/img/3969/1462/48009e641f454298f62e13de84ac0a09?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=FoVIRfH1WInwlIKMMgZmYjYlXvLqReTK9xzUdpBZNAgvFmhwHojhSJWFzr4D2cC1HLLjf4w5UxE8ebo9Yq3k7bj~NtTvkVNLafrIyUQT5rWXEJ2MhZq3QtfR92UKMYuuwVnl2-4Yf6ENHzJWr3H8qoYBwOjUr-r8V6JGS44z-OQ8nf3RvmbYYdXfKkIwLn2xj3eazB0DPjwGTdtS~IfWnG3vNtVeAfJ25kOVNI5tkIo8vk5X-MdJU3jakaOSnzkmSzvj1qPKscijg6BLDWtLLmDpmpYJEUO6uBlDPx6NNbC72zgk60LSn4L7F8Lq-jmVOeASx5BSPcZlumAz25R6Uw__",img1:"https://s3-alpha-sig.figma.com/img/db37/dd67/9040be5d5b5b374674cba3ba9bae96c7?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YCSVgKNO-k8CEg2lAEU03RxQp~WjgqWMuVqlNHDQq2EcnJjcCHeYEDRucyJKy8YXKnUUQUGQ--NzO7oknoUTvTiPVM~iT~bw-2L7ovVWGXPFj3GZ4Dl6JKK8uX6LMI86JQRgym61dRcAUf3Dkt0fsfjJ4wBkwsTAsnQtAfAY3GjRfiqwQGIbVIV1fKR-7JESpgEhPahkBEaqC2vonusJrLOM6fEyHpcWZZwIrTHc3Zsshl~HDx8NtSEU0Biy176CzYxteKskNAMo5hojpmUx8Dowp0x-eqv7Q9Ran5hhKFUXg59pAkL6lOm0Fp3iSMjZW8~MlA7bFc1klUQSS~OpNA__"}]
  const [current, setCurrent] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000);
    return () => clearInterval(interval);
  }, [current]);

  const handleNext = () => {
    setCurrent((prev) => (prev + 1) % imgDAta.length);
  };

  const handlePrev = () => {
    setCurrent((prev) => (prev - 1 < 0 ? imgDAta.length - 1 : prev - 1));
  };

  const getTransformStyle = (position) => {
    switch (position) {
      case 0: // Leftmost
        return { scale: 0.7, zIndex: 1 };
      case 1: // Second from left
        return { scale: 0.85, zIndex: 3 };
      case 2: // Center (biggest)
        return { scale: 1.2, zIndex: 5 };
      case 3: // Second from right
        return { scale: 0.85, zIndex: 3 };
      case 4: // Rightmost
        return { scale: 0.7, zIndex: 1 };
      default:
        return { scale: 0.5, zIndex: 0 };
    }
  };

  const getVisibleImages = () => {
    const visible = [];
    for (let i = -2; i <= 2; i++) {
      visible.push((current + i + imgDAta.length) % imgDAta.length);
    }
    return visible;
  };

  const imgDAta = [
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    
  ];

  const imgDAta1 = [
    {
      text: "Music",
      url: "https://s3-alpha-sig.figma.com/img/a28c/0ce4/2d37bef1b9760cc688b91c046818725b?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=SLeIzbH2Oi3rGxEUD-VemR7daz0e7FKmRG1nqtEsY2Z3QDhWoj7vS77gBLBqwA96syQJWFGczVPChxkSqm5OVki5ysi8obpHjA2TrcoT1~6hFn2VUeI27MESa4sR1LM1IdD53G8iedyxRjZB2Gh4Jio9cd0AneLycOYrt1CT4w-EXmcn0B8Yhbd6K2BwTGDDyxoXWzgDH8cbMF9hmszb5UvMiU1rTiWHw2uexVMeHNb9tLlaOK2Htl8WbZqq-ZRFVMGW3SnuREZhLqGAJ0~EqUzg9LGWXO87Ly1~iohQoftfmpwnq0F8nfQz6~E0TEwEwjCFvH0NlhRir9wg1jVr5A__",
    },
    {
      text: "Art",
      url: "https://s3-alpha-sig.figma.com/img/cdcf/4b80/6ae30377c88a9c32f95270fba0ed43f2?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ZFN4fKqVY83GWe4zz0kS3w3aSafLrYDdhvkj-Y39cv7fP5m9MW7boboZgoLowmvMoFLFtQyDkNfm9oZg1XeTVgf-I~q-4VpyYQRjhyx8a-qJ0VDFr24Vm-~10f0AJviebHlI0697yFXfePGwaqvOsVppb1nH2UTxJ-ZbpmNR~h1ou9Ly1pRgaNKGNfy1gG3jCbmwR22E28bQpGbetZr2Dgcx0jdLxAVBDQlPPAlM9xuygyUkZe~1sxbc1SlJAND-T3JyJ3y1Prg5jjfyZAN-UhyG3ATlB~EiuPFmgGOaPpS1MvmyWu3l~-cEX1Ghguy0ICaikmq-NNfSFfNxLh776A__",
    },
    {
      text: "Comedy",
      url: "https://s3-alpha-sig.figma.com/img/9486/548b/ef04ed43d452dd872c2d9ed2320ca618?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=N2HCqFEEcJebzuDp7KZdaWaWGjR7w3S09vmqTfkF8~YaoppVTLFYg9Xtm6XMlnYzuTfY904JPyDyLzYIpY-tRMZhR9OzkQl7aXi18zXlPJhthLdIpX56qwq1n~I5znTMkgY~HRmQr2Sw0BGmk6uxsdRdcMBf2a448w15sph-JRwUvkP9BEPJU6YP3M8SojQqyzY4BVf72m2wFOWX7ioyVkI9mwhGXwTsR-JlMAzV-KqHeVZ1jIF3QVzZdkRG7fQ1ffs2DeOQHJ6bMRV8c0uqj8kRoz87yBWEGNDdr4HPQ1Rc7t2AH0v-SIMR4QK1uOM4hzsCmr7UnUAysaxnaEE~HA__",
    },
    {
      text: "Workshop",
      url: "https://s3-alpha-sig.figma.com/img/93d3/2452/01c148edb5cad88ece5fcaf5ca712f48?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XrvfbjrE9wL9QtojEv2O5fClHcyL5cWX3fy~BXGoJ5dj2kqwl6R~9keup7pD1fHLziXZwE31TZLPlEpX2A2T~WxxNSHERJo8IbvpEGtvo75mU0CfDS8~fLJ4kPYN1ibheZBZL2O8RqdsfdG2g~h9yEQQigQ7si0Heu-Jia~u26jibJ1tI2tBeq-KdCLOmi6ZLmDID-PqSGScFNMF-xB6IIBeMq07fgx~cWRTIUBuvl1--k0IGOYishAPusfYRrcq23C70BUXjVkhydIFWPb62dlu2W-07ypgXaiiNDv1wzQUflegtEUfFpps1c-0dAhpuSjMjmdbhic0FBULZKEfIw__",
    },
    {
      text: "Dance",
      url: "https://s3-alpha-sig.figma.com/img/8c30/ec02/20c6065128f6cd5fa3730f328594ccf6?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fckhWKGr4ebHY5bo3F3dgVHBtFCGl2LNrmV02XPp3ndZ2dwlC0pvY2cebiruoFQcbaKJZOjbLZoadXOfTC6y1NrzBffjhi2~YVw-7tbbMULLPsMoIchguT9ceiHEKj8zqKpYYYlCz7W2w5ujiaAp04QgxqLJR3Y3Y6-5~ak~dVrEHayBsthRkdr~qDBa3y~kNKMKqKzH4L1O7A7Yd~YIMlsw2NH2nqMWIV4J2F5anD87g5pQLFQ5tJs5BToKzB5xnargsEjcgubeg4NVgm90eevyiBKBTgPJ3Py~97J9Pa~ro1WUhsAxb1TiLZKs4j-BKPr3-EAsMJB~f5qQel5tgQ__",
    },
    {
      text: "Party",
      url: "https://s3-alpha-sig.figma.com/img/ec5e/e758/c4789a52632ea4d5c3a5e4f10864384e?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ax3jB02uoQwmQhdaV22z-4ONP8W0LjHk~9PtsDXnECmEhUiC7DWk~yoa0ERUNYdICQ94l~Ju909nVWXYUNlELRWgUnpZ9Xwo0eXf2O-RhXtHvHUuVX-dbm9xkT-vYoyBpmT6IOryeLpQDnX7AUXYy5XlgPp9ldjqj398uNcUskLIPTcGjRg-oINu2eZ5ncaDJcOZm2U7SnnYnyoWm1SVeSomQB2q1PUN8tD3jTEXktprpuUp~wFPpBjkABmf7BHSteU0cg85H0TZya8~kXjzX2ojKKRsZXtqkoe77lypbX9dj9bWw3~cweuzB2SyhU~f3mt5VFrYa9eBZfXDWOy~6Q__",
    },
    {
      text: "Anniversary",
      url: "https://s3-alpha-sig.figma.com/img/8929/027e/c6b59b15e6acf5329a2e3c6d7a59d6eb?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=XHJQ89ei0LSM7A-Xhze44udAIiUmoolij0kQflXhzvrHoOGtOv7iCr83NhUgQhQcYWSURKvDyq-cx93UNKTAXab9U6277S7ddtNd19GTkC5W8ln~xzECb8DMte3pgrbrz9NpnDrpZ2nNCO-y2I--MNtwM7A41xcgOw3Zz~ii39tw89XpgtLDhxVXVZYs5yi5mugWxXbthyYGW2z7-KcedUbk4uRM6FSBmgiW7gbJbWqeXFqWzziKx0MMen22uqP2MKytfDXaOBWY5-UchHrViY-vawwu3PHHA1D14rwWEtKYjH7JwfVerhlS37gS96WqcrWwpV3g2GnhHK8puEgDig__",
    },
    {
      text: "Online",
      url: "https://s3-alpha-sig.figma.com/img/548d/65da/1bb346de9878f953f09257ff97bf0b0b?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fDTyrtCtWzHY1E8SWNZByIvS2iVLLQoKWP8t4-9XuSzEth~QvCPTapl6P83RlKiFpcVUHl2AnIaCTfpWM9lydCb7zlq~c1BbjYNaYsq1O-AQHsIlJAOe~U4D4vSAr9-z--W~bNmpossKWX6qL~dkN6r14-cnyKEtxnYgjhsP6XRfCOwz~AGGIqbNoS6pFevOjIScRYahCjsb~kr4M2wRv2vnZMlmqH3vUuh~S~lT30pkoy7QBOPaKR9CmmNZVYV7Yp6UZL0BlsVcGzsf4asdH9otfBalVFRHkHqY8soPRaLQVgOFuzoH7bN-pB9qzz3NlyiIryynqQn66DrLo0cf~g__",
    },
  ];

  const [slider, setSlider] = useState(0);
  const [transitioning, setTransitioning] = useState(true);

  useEffect(() => {

    const sliderInterval = setInterval(() => {
      if (slider === imgDAta.length - 1) {
        setTransitioning(false);
      }
      setSlider((prevSlider) =>
        prevSlider === imgDAta.length - 4 ? 0 : prevSlider + 1
      );
    }, 2000);

    return () => clearInterval(sliderInterval);
  }, [slider]);

 
  useEffect(() => {
    if (slider === 0) {
      setTransitioning(true);
    }
  }, [slider]);

  return (
    <div
      className="bg-black text-white font-sans bg-cover"
      style={{ backgroundImage: `url(${img1})` }}
    >
        <section className="text-center py-1">
          <h1 className="text-4xl font-bold">Discover the Eventlane App!</h1>
          <p className="text-gray-400 mt-4 max-w-xl mx-auto">
          Your ultimate event companion, now available on Google Play and the App Store.
          </p>

          <div className="w-full flex justify-center items-center py-8 relative">
            <div className="relative w-full sm:w-[90%] md:w-[80%] lg:w-[80%] pt-32 h-[600px] overflow-hidden flex justify-center items-center">
              {getVisibleImages().map((index, position) => {
                const { scale, opacity, zIndex } = getTransformStyle(position);

                return (
                  <div
                    key={index}
                    className="absolute h-full transition-transform duration-1000 ease-in-out"
                    style={{
                      transform: `translateX(${
                        (position - 2) * 120
                      }%) scale(${scale})`,
                      zIndex,
                      opacity,
                    }}
                  >
                    <img
                      src={imgDAta[index].url}
                      alt={`Slide ${index + 1}`}
                      className="w-60 h-fit object-cover"
                    />
                    
                  </div>
                );
              })}
              
            </div>
            
          </div>
          <div className="flex justify-center items-center gap-4">
          <img src={google} alt="Google Play" className="w-80 h-12 " />
          </div>
        </section>
      


        {/* <section className="py-12 relative  mx-auto">
 
  <div className=" mx-auto">
    <div className="mx-auto">
    <h2 className="text-3xl text-white z-40 w-11/12 sm:ml-32  font-bold text-start mb-8">
      Explore Categories
    </h2>
    </div >

   
    <div className="relative">
      <div className="absolute w-full h-full bg-black opacity-30"></div>
      <img
        src={event}
        alt="Explore Categories"
        className="h-[20rem] sm:h-[27rem] md:h-[20rem] lg:h-[15rem] w-full object-cover border-1 border-white"
      />
    </div>
  </div>

 
  <div className="flex z-40  w-full lg2xl:w-11/12  flex-wrap justify-center items-center absolute top-[10rem] gap-12">
    {imgDAta1.map((category, index) => (
      <div
        key={index}
        className="text-center flex flex-col items-center"
      >
        <img
          src={category.url}
          alt={category.text}
          className="w-[3rem] h-[3rem] sm:w-24 sm:h-24 lg:w-28 lg:h-28 rounded-full object-cover border-2 border-white z-20"
        />
        <p className="mt-2 text-white text-sm md:text-base lg:text-lg">
          {category.text}
        </p>
      </div>
    ))}
  </div>
</section> */}



<section className="py-12 relative  mx-auto">
 
  <div className="">
    <div className=" mx-auto">
    <h2 className="text-3xl text-white z-40 w-11/12 sm:ml-32  font-bold text-start mb-8">
      Explore Categories
    </h2>
    </div >

   
    <div className="relative">
      <div className="absolute inset-0 bg-black opacity-30"></div>
      <img
        src={event}
        alt="Explore Categories"
        className="h-[20rem] sm:h-[27rem]   lg:h-[15rem] w-full object-cover border-1 border-white"
      />
    </div>
  </div>

  <div className="absolute inset-x-0 top-[10rem] sm:top-[12rem] lg:top-[10rem] ">
  <div className="flex  z-40  w-full lg2xl:w-11/12  flex-wrap justify-center   gap-[1.5rem] sm:gap-[3.5rem]">
    {imgDAta1.map((category, index) => (
      <div
        key={index}
        className="text-center flex flex-col items-center"
      >
        <img
          src={category.url}
          alt={category.text}
          className="w-[4rem] h-[4rem] sm:w-[7vw] sm:h-[15vh]  rounded-full object-cover border-2 border-white shadow-lg"
        />
        <p className="mt-2 text-white text-sm md:text-base lg:text-lg">
          {category.text}
        </p>
      </div>
    ))}
  </div>
  </div>
</section> 





      <section className="py-12">
        <div className="flex flex-col justify-start items-start w-[80%] mx-auto">
          <h2 className="text-3xl font-bold text-center">Real Stories, Real Experiences</h2>
          <p className="text-center text-gray-300 mb-12">
          Discover what makes our users love us!
          </p>
        </div>
        <div className="flex flex-wrap justify-around gap-3  w-10/12 mx-auto mt-2">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="bg-[#262626] px-6 py-6  rounded-lg  md:max-w-[30%] text-center"
            >
              <p className="text-white h-[50%] leading-loose ">{testimonial.text}</p>


              <div className="flex justify-center">
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <span
                      key={index}
                      className={`h-5 w-5 font-bold ${
                        index < testimonial.rating
                          ? "text-yellow-400"
                          : "text-gray-500"
                      }`}
                    >
                      ★
                    </span>
                  ))}
              </div>
              <div
                className="w-12 mt-3 h-12 rounded-full bg-gray-600 mx-auto bg-cover"
                style={{ backgroundImage: `url(${testimonial.url})` }}
              ></div>

              
              <div className="mt-4">
                <strong className="block text-white">
                  {" "}
                  {testimonial.name}
                </strong>
                <span className="text-sm text-gray-400">
                  {" "}
                  {testimonial.role}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer
        className="bg-gray-800 py-20 bg-cover w-full  border-y-[1px] border-white"
        style={{ backgroundImage: `url(${img2})` }}
      >
        <div className="flex justify-around gap-1 md:gap-2  text-center text-white">
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl font-bold ">151+</h3>
            <p className="text-sm sm:text-xl ">Thrilled Partygoers and Counting!</p>
          </div>
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl  font-bold ">100+</h3>
            <p className="text-sm sm:text-xl">Cities Lighting Up with Events!</p>
          </div>
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl  font-bold ">10K+</h3>
            <p className="text-sm sm:text-xl">Unforgettable Events Organized!</p>
          </div>
          <div>
            <h3 className="text-base sm:text-4xl  font-bold ">2500+</h3>
            <p className="text-sm sm:text-2xl">Trusted Partners and Growing!</p>
          </div>
        </div>
      </footer>

      <section className="py-24">
        <h2 className="text-3xl font-bold text-start  w-[80%] mx-auto">
          Latest Posts
        </h2>
        <div className="flex flex-wrap w-10/12 mx-auto justify-center gap-[3.2rem] mt-8">
          {im.map((post, index) => (
            <div className="max-w-[23rem] md:w-[30%]   text-white rounded-lg overflow-hidden">
             
              <img
                src={post.img} // Replace with your image URL
                alt="Event"
                className="w-[100%] h-[16rem] rounded-2xl "
              />

              {/* Content */}
              <div className="py-4">
                {/* Tag */}
                <span className="bg-[#262626] text-sm text-[#f5167e] uppercase font-semibold px-3 py-1 rounded-md">
                  Events
                </span>

                {/* Title */}
                <h2 className="text-lg font-bold mt-3">
                  Lorem ipsum is a placeholder text commonly used to demonstrate
                  the visual form.
                </h2>

                {/* Author and Date */}
                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center space-x-2">
                    <img
                      src={post.img1}
                      alt="Author"
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-sm text-[#97989f]">
                      Tracey Wilson
                    </span>
                  </div>
                  <span className="text-sm text-[#97989f]">
                    August 20, 2022
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Thirdpage;
