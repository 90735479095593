



import React, { useState ,useEffect} from 'react'

import img from "../../assets/Logo.png";
import logo from "../../assets/Logo.png";
import img1 from "../../assets/navcolor.png";
import img2 from "../../assets/nav.png";
import OtpInput from "react-otp-input";
import bg1 from "../../assets/bg1.png";
import bg2 from "../../assets/bg2.png";
import bg5 from "../../assets/bg5.png";
import footer from "../../assets/footer.png";
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../../components/common/Navbar';

const Fourthpage = () => {

  const location = useLocation();  // Hook to access the current location object
  const event  = location.state?.event;
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };


    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timer, setTimer] = useState(51);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  

  // Handle OTP input change
  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus next input
    if (element.nextSibling) element.nextSibling.focus();
  };

  // Resend Timer
  useEffect(() => {
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [timer]);

  const handleProceed = () => {
    alert(`Phone: ${phoneNumber}, OTP: ${otp.join("")}`);
  };

    const [earlyBirdTickets, setEarlyBirdTickets] = useState(0);
    const [selectedTickets, setSelectedTickets] = useState(0);
    const [formData, setFormData] = useState({
      fullName: "",
      gender: "",
      day: "01",
      month: "Jan",
      year: "2004",
    });
    const ticketPrice = 1000;
    const generalPrice = 1500;
  
    const total = earlyBirdTickets * ticketPrice || generalPrice;
  
    const handleTicketChange = (e) => {
      setEarlyBirdTickets(parseInt(e.target.value, 10));
    };

    
   
    
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  return (
    <div>
      <Navbar/>







       
      <div className=" bg-black text-white flex items-center  bg-cover justify-center" style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}>
      <div className="w-full flex flex-col max-w-5xl mt-[8rem]  rounded-lg  p-6">
 
 <div className="  text-white flex  justify-center  ">
      <div className="w-full max-w-3xl h-[15rem] sm:h-[22rem] rounded-lg sm:p-2">
        
      <div className="flex items-center  space-x-6">
          <img
            src={event.getGalleryDetail[0]['file']}
            alt="Webinar"
            className="w-[8rem] h-[9rem] sm:w-[18rem] sm:h-[18rem] rounded-lg border-3 border-white"
          />
          <div className=''>
            <p className="text-[#cccccc] text-xs sm:text-3xl mb-1">{event.category ? event.category['name'] : ''}</p>
            <span className=" text-sm sm:text-5xl font-semibold">
            {event.event_name}
            </span>
            <div className="flex flex-col items-start gap-1 sm:mt-4 sm:space-y-4">
            <div className="flex gap-2 items-center space-x-2 text-xs sm:text-3xl text-[#f5167e]">
                <i className="far fa-calendar-alt"></i>
                Sat Oct 05, 09:00 PM
              </div>
              <div className="flex gap-2 items-center space-x-2  text-xs sm:text-3xl text-white">
                <i className="fas fa-map-marker-alt"></i>
                {event.venue}
              </div>
            </div>
          </div>
        </div>

        
        



      </div>
    </div> 








    




<div className="bg-[#262626] text-white  sm:w-[65%] mx-auto px-2 py-3 mt-2 sm:mt-10 rounded-3xl mb-5 ">


<div className="bg-[#262626] text-white p-6 rounded-lg max-w-full mx-auto text-xs sm:text-xl">
     
 
<div className="space-y-4">
          <div>
            <label className="block text-sm mb-2">Phone Number</label>
            <div className="flex items-center gap-2">
              <select
                className="bg-black border border-gray-600 rounded px-3 py-2 text-white text-sm"
              >
                <option value="+91">IN +91</option>
                
              </select>
              <input
                type="number"
                placeholder="Please enter your Number"
                className="flex-grow bg-black border border-gray-600 w-14 rounded px-3 py-2 text-white text-sm"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </div>
          </div>

          
          <div>
            <p className="text-sm text-white">
              An OTP has been sent to{" "}
              <span className="text-white">
                {phoneNumber || "+91-0000000000"}
              </span>
            </p>
            
            <label className="block text-sm mb-2 mt-4">Enter the OTP</label>
              <div className="flex justify-between gap-2">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={6}
                  renderInput={(props, index) => (
                    <input
                      maxLength={1}
                      onChange={(e) => {
                        const { value } = e.target;
                        const newOtp = [...otp];

                        if (/^[0-9]$/.test(value)) {
                          newOtp[index] = value;
                          setOtp(newOtp);

                          if (e.target.nextSibling) {
                            e.target.nextSibling.focus();
                          }
                        } else if (value === "") {
                          newOtp[index] = "";
                          setOtp(newOtp);
                        }
                      }}
                      style={{
                        boxShadow:
                          "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                      }}
                      className="bg-[#262626] w-[40px] h-[40px] sm:w-[90px] sm:h-[40px] border-b-2 bg-richblack-800 text-white text-center focus:border-0 focus:outline-2 focus:outline-yellow-50"
                    />
                  )}
                  containerStyle={{
                    justifyContent: "space-between",
                    gap: "0 6px",
                  }}
                />
              </div>
              <div className="flex justify-between">
                <p className="mt-2 text-sm text-white">
                  Resend in{" "}
                  <span className="text-white">
                    00:{timer < 10 ? `0${timer}` : timer}
                  </span>
                </p>
                <div className="flex items-center gap-3">
                  <div
                    className="relative inline-block w-11 h-6 cursor-pointer"
                    onClick={() => setReceiveUpdates(!receiveUpdates)}
                  >
                    <input
                      id="switch"
                      type="checkbox"
                      checked={receiveUpdates}
                      onChange={() => {}}
                      className="sr-only"
                    />
                    <div
                      className={`w-full h-full rounded-full transition duration-200 ${
                        receiveUpdates ? "bg-[#f5167e]" : "bg-gray-700"
                      }`}
                    ></div>
                    <div
                      className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 transform ${
                        receiveUpdates ? "translate-x-5" : ""
                      }`}
                    ></div>
                  </div>
                  <label
                    htmlFor="switch"
                    className="text-[11px] sm:text-md text-gray-400"
                  >
                    Receive Updates via WhatsApp
                  </label>
                </div>
              </div>
          </div>

          
         
        </div>

        
        

        <label className="w-full flex gap-3  text-white rounded-3xl text-[10px] sm:text-lg font-semibold py-3 mt-6  transition duration-200">
          <input
            type="checkbox"
            // checked={''}
            // onChange={''}
            className="accent-[#f5167e] w-3 h-3 sm:w-5 sm:h-5 "
          />
          I agree to Event Organizer's Terms and Conditions , refund policy and Privacy Policy.
        </label>


        <div className="flex justify-center items-center ">
          
  <button
    className={`mt-6 w-[70%] p-3 rounded-3xl font-medium ${
      true
        ? "bg-white text-[#f5167e]"
        : "bg-gray-300 text-gray-500 cursor-not-allowed"
    }`}
    disabled={!(true)}
  >
    Proceed
  </button>
</div>

     
    </div>

      
    </div>
      </div>
    </div>
  





{/* footer */}

<div className="relative ">
      <img src={bg5} className='absolute -top-20 h-64 w-[100vw] z-0'/>
      
      <div className="relative h-[35rem] z-10">
        
        <img
          src={footer}
          className="w-full h-[35rem] object-cover "
          alt="Background"
        />
      </div>
      <div className="absolute top-0 left-0 right-0  z-40  text-white flex flex-col justify-center items-center h-[40rem] w-full">
        <h1 className="text-[2.5rem] md:text-[3.5rem] text-center">
          Ready to Host an Event? 
        </h1>
        <h2 className="text-[1.5rem] md:text-[2rem] text-center">
          Get Your Event Live in Less Than 3 Minutes
        </h2>
        <Link to='/business'  className="block no-underline">
        <button className="z-40 text-[#f5167e] bg-white rounded-[3rem] mt-12 py-3 px-16 md:py-3 md:px-24">
          List Event
        </button>
        </Link>
      </div>


      
      <footer className="bg-[#262626] text-white py-8 md:py-16">

        
  <div className=" mx-auto h-[105vh] md:h-[47vh] ">
    
    <div className="flex-wrap md:flex md:flex-nowrap   w-[90%]  mx-auto ">

    <div className="w-full md:w-1/3 flex flex-col items-start  ">
          <div className="text-2xl font-bold mb-1 sm:mb-4 ">
            <img src={logo} alt="Eventlane Logo" className="w-3/4  md:mx-0" />
          </div>
          <p className="text-sm w-[90%] ">
          Eventlane is your one-stop platform for discovering, planning, and attending events that matter to you. We connect people with experiences they'll love!
          </p>
          <div className="flex justify-center md:justify-start mt-1 sm:mt-4 text-xl md:text-3xl space-x-4">
            <a href="https://www.facebook.com/eventlaneapp" target='blank' className="text-white ">
              <i className="fab fa-facebook" ></i>
            </a>
            <a href="https://www.instagram.com/eventlaneapp/?igsh=MWRzcWNtODRna2p5ag%3D%3D#" target='blank' className="text-white ">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/eventlane/" target='blank' className="text-white ">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
    
      <div className="flex gap-[4rem] w-full md:w-1/3">
        
        
        
        <div className=" w-1/2 flex-col justify-end items-end">
          <h3 className="text-lg font-semibold mb-4 text-start">Customers & Organisers</h3>
          <h4 className="space-y-2 text-sm"></h4>
            <div className='flex-col gap-2'>
            <p>Explore and Discover</p>
            <p>Create and Sell</p>
            <p>Manage and Invite</p>
            <p>Engage and Inspire</p>
            <p>Join and Enjoy</p>
          
            </div>
        </div>

        <div className=" w-1/2 flex-col text-start justify-center items-center">
          <h3 className="text-lg font-semibold mb-4 text-start">Eventlane</h3>
          <h4 className="space-y-2 text-sm"> </h4>
            <p>For Business</p>
            <p>Blog</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
         
        </div>
      </div>

      
      <div className="flex  gap-[4rem] w-full md:w-1/3">
        
        

        
        <div className="w-full flex flex-col items-start">
          <h3 className="text-lg font-semibold mb-4">Never Miss an Update!</h3>
          <p className="text-sm mb-4">
          Sign up for our newsletter and be the first to know about the latest events and concerts.

          </p>
          <div className="flex items-center relative w-full">
            <div className="flex items-center border-2 border-[#f5167e] bg-white rounded-[2rem] w-full px-4 py-2">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                className="w-full bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-2"
              />
            </div>
            <button className="absolute right-2 px-4 py-2 bg-[#f5167e] rounded-[2rem] text-white ">
              Subscribe now
            </button>
          </div>
        </div>
      </div>
    </div>

   
    
  </div>
  <div className="w-[85%] mx-auto border-t border-gray-600 pt-6 text-center text-sm text-gray-400">
      Copyright © 2024 Softtura. All rights reserved.
    </div> 
</footer>

    </div>


    <div className="bg-[#262626] text-white p-2   flex justify-between mx-auto max-w-screen-full fixed bottom-0 z-40 w-full">
  <div className='flex justify-around w-full'>
  <div className="text-right flex flex-col items-start">
    <p className="text-lg">Total</p>
    <p className="text-2xl font-semibold">
      Rs. {selectedTickets * 1000 || 1500}
    </p>
  </div>

  <div>
  <Link to='/my-events'  className="block no-underline" state={{ event: event }}>
    <button
      className={`bg-[#f5167e] text-white transition-colors mt-6 py-3 px-6 rounded-3xl font-medium `}
     
    >
      Pay Now
    </button>
    </Link>
  </div>
  </div>
</div>




    </div>
  )
}

export default Fourthpage






