import React from "react";
import img1 from '../../assets/bussiness3.png'
import img2 from '../../assets/bussiness4.png'
import img3 from '../../assets/bussiness5.png'
import hhhh from '../../assets/hhhh.png'
import img4 from '../../assets/hello.png'
import bg7 from '../../assets/bg7.png'
import bg1 from '../../assets/bg1.png'
import bg2 from '../../assets/bg2.png'
import mobile from '../../assets/mobile.png'
import one from '../../assets/01.png'
import two from '../../assets/02.png'
import three from '../../assets/03.png'
import './second.css'
import { height } from "@fortawesome/free-solid-svg-icons/fa0";

const Second = () => {
  const features = [
    {
      number: one,
      title: "Sell Tickets",
      description:
        "Sell tickets easily with a seamless process that prioritizes your customers.",
      imageSrc: img1,
    },
    {
      number: two,
      title: "Build a Following",
      description:
        "Connect with your audience and grow your brand using our advanced tools.",
      imageSrc: img2,
    },
    {
      number: three,
      title: "Promote Directly to Them",
      description:
        "Target your audience with tailored promotions and notifications.",
      imageSrc: img3, 
    },
  ];

  const footerFeatures = [
    {
      title: "Sell Tickets",
      description: "Our ticketing experience is optimised for you and your customers. Our web pages are designed for speed and search, so your events are found quicker, and customers checkout faster. And it’s even better in our apps, where 70% of our ticket sales are made",
      img:'https://s3-alpha-sig.figma.com/img/b417/8168/aa4b35acc7e119cace70a325c127c9d7?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=osH47U1L54SxCbod4fc8nTmvJFoplUYvgzySA5IEYhkEkeQUUa9VQGvnnLdlxJ2clRp1Uv5fbo9qPh906QjBPCC3l0q~u3H~sHJVHadCTA1AVcVLXIdDtY2FwyGZl~1DHagEw6rhVWmOrPB8eKBisyGu85SH2WCEaXFQCLbzetXw3uwKFqtdhstMM03e5E-de11nZ~SQMTT-h4tGACZhKA6EWSNJLgg2mtIY4IxZ-~TfclafehINLLz2ISSI5FKonjsW19hw1vw7WsofrVk1VDDyFd6sAmkFIqSVK20GrnW2914jUvy7gHOOQrscjThfrAiF2xPcC3LzN9WH7GRzSQ__'
    },
    {
      title: "Market Your Events",
      description: "ach more customers with our unique promotional tools and world-leading customer apps. And integrate with the most popular ad and analytics platforms so you can optimise all your marketing channels too.",
        img:'https://s3-alpha-sig.figma.com/img/fd92/221b/f517fa8ee24c97eb16245b68abaf7b3f?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=R-mHg38b8q0f7595Itcqv~owb5MacLeenlm~znZzd17Tqbg6e4pd-p~xSmP5Var9s3v840BWd0YJrhhz9QsKsOQR~jdo5cGgGZpyzWGUE1uvAkptgmcElYbMUfxop6RjGZ~GaMXM5-eqe5qTLaE3Va9BioJ967oClzj2P0lWImmgA2ilHXPlNx0dEGLTddOZe29WUzZeHxAeTDlN0AMJJnD-D0J6iKjRI90DcAKMGD46jt4adt4xbkDrvSxzdfy-uWi4Zci8GRDZjQEIraK4FOkyTNon2cqnX1Fcjak7ZfpBNVrmKVlgG7M54NDF2ha8rgv16gvK4hW-RXPSRk8WMQ__'
    },
    {
      title: "Manage Operations",
      description: "Get customers through the door faster with Sort My Scene Scan, our robust and reliable ticket scanning app for iPhone and Android.",
        img:'https://s3-alpha-sig.figma.com/img/dd04/7d61/bedf5f94365cf9a1f39706054b481b32?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=QN6jEV9B6q8E17P0DoQtiGogUVNfVrrV~ka5ZY~uHO0RCL~-ODmuLSS9uLiM-~g2zQUuXnTleJfcuqf6fVck8NRbJEDik13jKngiWnqYdopmoL7RZvTtDAKbLXxIYv1hzEMbXQar6j0bQTAGtGu8Sj48eKaBXKy7JJFOQR1W~ICa~n~7prCm7X4CuD~oIczmMaB1bwLsLyENmMyFvtj~Co814I4H3SJmlz0MrB3f6u1IrEEaOAIitAJ6GqvuLdAsmJYiOgbyiqtF7tp2iMmxJdjBcAOEyuHceev8jIZFVD0gNXrNBuTjf9hTTJltAvAZhSEGkIl4mkZKW8-FlDDiaw__'
    },
  ];

  return (
    <div style={{ backgroundImage: ` url(${bg2})` }} className="bg-black bg-cover text-white ">
      <img src={bg7} className='z-10  h-[25vh] w-full absolute bottom-48  sm:bottom-12 ' />
      

      <div className=" text-white pt-16 flex flex-col lg:flex-row items-center w-11/12 mx-auto">
      
      <div className="w-full  md:w-1/2  flex justify-center">
      <img
        src={img4}
        
        className="rounded-lg justify-items-center w-full h-full"
      />
    </div>

      
      <div className=" lg:w-1/2">
        <h1 className="text-3xl lg:text-4xl font-bold mb-4">
        Promote and Manage Your Events with Ease on Eventlane!
        </h1>
        <p className="text-lg text-gray-300 mb-6">
        A platform designed to help you build a loyal customer base, increase sales, and amplify your marketing like never before.
        </p>
        <button className="bg-[#f5167e] text-white py-[10px] px-16 rounded-full text-xl shadow-lg transition duration-300">
        <a className="text-white no-underline" href="https://play.google.com/store/apps/details?id=com.event.sandesha">List event</a>
        </button>
      </div>
    </div>


    <div className="flex-col sm:flex-row">

  <div className="text-white py-10 px-6 md:px-12 lg:px-20 flex flex-col lg:flex-row gap-20 items-center w-11/12 mx-auto">
    <div className="lg:w-1/2 text-start lg:text-left">
      <img className="h-[8rem]  lg:mx-0 mb-4" src={one} alt="Feature 1" />
      <h3 className="text-2xl font-semibold mb-4">{'Showcase Your Event in Style'}</h3>
      <p className="text-lg text-gray-300">
        {'Showcase your event with a stunning, user-friendly layout. Add all essential information and visuals to captivate your audience and get them excited to attend.'}
      </p>
    </div>
    <div className="w-full lg:w-1/2 mt-4 lg:mt-0 flex justify-center">
      <img
        src={hhhh}
        alt="Feature Image 1"
        className="rounded-lg w-3/4 lg:w-full"
      />
    </div>
  </div>

  {/* Section 2 */}
  <div className="text-white py-10 px-6 md:px-12 lg:px-20 flex flex-col-reverse lg:flex-row gap-20 items-center w-11/12 mx-auto">
    <div className="w-full lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
      <img
        src={img2}
        alt="Feature Image 2"
        className="rounded-lg w-3/4 lg:w-full"
      />
    </div>
    <div className="lg:w-1/2 text-start lg:text-left">
      <img className="h-[8rem]  lg:mx-0 mb-4" src={two} alt="Feature 2" />
      <h3 className="text-2xl font-semibold mb-4">{'Engage Guests with Live Updates'}</h3>
      <p className="text-lg text-gray-300">
        {'Engage with your guests through real-time updates, announcements, and photos. Keep your event at the top of their minds and build excitement leading up to the big day.'}
      </p>
    </div>
  </div>

  
  <div className="text-white py-10 px-6 md:px-12 lg:px-20 flex flex-col lg:flex-row gap-20 items-center w-11/12 mx-auto">
    <div className="lg:w-1/2 text-start lg:text-left">
      <img className="h-[8rem]  lg:mx-0 mb-4" src={three} alt="Feature 3" />
      <h3 className="text-2xl font-semibold mb-4">{'Track Sales & Bookings Effortlessly'}</h3>
      <p className="text-lg text-gray-300">
        {'Monitor ticket sales with ease. Our efficient tracking system ensures that you can manage attendee information and optimize sales for the best results.'}
      </p>
    </div>
    <div className="w-full lg:w-1/2 mt-8 lg:mt-0 flex justify-center">
      <img
        src={img3}
        alt="Feature Image 3"
        className="rounded-lg w-3/4 lg:w-full"
      />
    </div>
  </div>
</div>




{/* {features.map((feature, index) => (
  <section
    key={index}
    className={`flex flex-col md:flex-row items-center justify-center px-16 py-16 ${
      index % 2 == 0 ? "md:flex-row-reverse" : "items-center justify-center"
    }`}
  >
    <div className="w-full h-full md:w-1/2 px-4 flex justify-center">
      <img
        src={feature.imageSrc}
        alt={feature.title}
        className="rounded-lg justify-items-center"
      />
    </div>
    <div className="w-full md:w-1/2 px-4 flex flex-col items-center md:items-start  justify-items-end  text-center md:text-left">
      <h2 className="text-5xl font-bold text-[#f5167e] mb-4">{feature.number}</h2>
      <h3 className="text-2xl font-semibold mb-4">{feature.title}</h3>
      <p className="text-lg text-gray-300">{feature.description}</p>
    </div>
  </section>
))} */}


<section className=" text-white pt-16 w-11/12 mx-auto">
  <h2 className="text-center mx-4  text-4xl font-bold mb-8">
  Bold ideas, backed by a solid foundation—ensuring everything   works seamlessly.
  </h2>
  <div className="  text-white py-8">
      <div className="w-11/12 mx-auto ">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          
          <div className="text-center md:text-left">
            <div className="relative mb-6">
              <img
                src="https://s3-alpha-sig.figma.com/img/b417/8168/aa4b35acc7e119cace70a325c127c9d7?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=qeuD~Up0hYh~jCyV4RpO~X-Ty1awubUbH-~nGu1Fsf0UsjCwfB8RRmMEdsLEVfY4xbw9K-eAipT8pGDuqjYo-W5V1BLbfSDwwq0nD6EXkkAffbpGO7t~hCMr-AqmeqeyiOjltSVnvVzY5~Q3ZN911LyIvtc7s~cz5rXEQKit7014oFOK1kfZ-RS0qfwuv4JedMlSbsZPQHnmnFlwUm4joh0gJrlNfc5c9BPNZ6mt1jNndnFqzHuhqDVKkMbJnGnAbZtIKWTBNYSDDOISUyapneyK1Axy0AKOXusHrXfAAXsj2JSR-eEHm0ac4l~BZQY8A8BeVPL~pj49bIz1L2z4dw__"
                alt="Sell Tickets"
                className="w-full h-64 max-w-[250px] md:max-w-[300px] lg:max-w-[450px] mx-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="flex flex-col px-3 items-start">
            <h3 className=" text-2xl md:text-4xl  mb-2">Get Discovered Fast</h3>
            <p className="text-gray-300 text-start">
            Our optimized platform ensures your events are easy to find and book.
            </p>
            </div>
          </div>

          <div className="text-center md:text-left">
            <div className="relative mb-6">
              <img
                src="https://s3-alpha-sig.figma.com/img/fd92/221b/f517fa8ee24c97eb16245b68abaf7b3f?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=j-J7MsW9hG94Ufsy6O3dKxqqRaPsAN-28BC-dIiclBz~fj2UvCkoLEyCl3AoERe8GrOA8T~ysVB2e~5Y8S8JAhlVFy-mx83UOUFm2je-uZZWlmye1qDjmHt1ChfrjGKuWAI9FobthqutIaFYj3LOWjs7L1B0kqdT7IIn776LNVyzSjROn4horSTziSGxzNw8NjsXtG6NBtJBIIj7I15EyZt06ZrSrjykBPfUzwr~6UsZa1lGh~IGK~~nIofEuK769ubxrggGtZ8IztAt~SUjE6jt3Q07FIpq1QE6hhIxBtWu7~~ln7lmH6kyjPzQSw06BGoHGGdeuMrZ1idjlcSeeQ__"
                alt="Sell Tickets"
              
                className="h-64 w-full max-w-[250px]  md:max-w-[300px] lg:max-w-[450px] mx-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="flex flex-col px-3 items-start">
            <h3 className="text-2xl md:text-4xl mb-2">Sell More, Stress Less</h3>
            <p className="text-gray-300 text-start">
            Speedy and user-friendly processes for higher customer satisfaction.
            </p>
            </div>
          </div>

          
          <div className="text-center md:text-left">
            <div className="relative mb-6">
              <img
                src="https://s3-alpha-sig.figma.com/img/dd04/7d61/bedf5f94365cf9a1f39706054b481b32?Expires=1738540800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Ly-mpzlY~tdz685BL8shljsTpC2WlUxXZemzEunrNlKX~NILQVnf1XxatWogY2uZz3yEhUrc5YsIEyhJfrSToYFgzm6S98rYFMVrYE7yYtFvUyMxMFEFCVxrRaUozJKRuirJ2g9xq07gccPDcQAY15O2uL1cz35IA66NYr9ug61RuQMSN61I-AdoUZTU0mtzTYFcHt26cyzIxcFqofUYFO3VjYdXnKFJXbF~ecXHgO0rQLGHUYlTLk89Et71Szv49a8elhXo2E3aKo2lGI~LnJkY9mEzlHoGbnFvbxyRuqV73AsQnftRtP-spTL9kh5N6igRa2Vhd7X2fo3DOvYcIQ__" 
                alt="Manage Operations"
                className="w-full h-64  max-w-[250px] md:max-w-[300px] lg:max-w-[450px] mx-auto rounded-lg shadow-lg"
              />
            </div>
            <div className="flex flex-col px-3 items-start">
            <h3 className="text-2xl md:text-4xl mb-2">App-Driven Sales</h3>
            <p className="text-gray-300 text-start">
            70% of ticket sales happen through our app, maximizing your reach.
            </p>
            </div>
          </div>
        </div>
      </div>
    </div>
</section>

      
<div className=" min-h-[80vh] flex justify-center items-start p-6 lg:p-8">
  <div className="flex mb-5 flex-col gap-5 lg:flex-row items-center w-11/12 mx-auto ">
    {/* Image Section */}
    <div className="w-full lg:w-1/2 flex justify-center relative">
      <img
        src={mobile} 
        alt="Mobile 1"
        className="w-fi sm:w-3/4 lg:w-2/3 xl:w-full lg:h-full max-w-xs lg:max-w-xl shadow-lg"
      />
    </div>

    {/* Text Section */}
    <div className="w-full lg:w-1/2 text-center lg:text-left flex flex-col items-center lg:items-start">
      <h2 className="text-white text-3xl sm:text-4xl font-bold mb-4">
      Built to Reflect Your Excellence
      </h2>
      <p className="text-gray-300 text-base lg:text-start sm:text-lg mb-6 leading-relaxed">
      We focus on creating exceptional experiences, designed to mirror the quality and care you put into your events, ensuring your business always shines.
      </p>
      <button className="bg-[#f5167e] text-white font-semibold px-8 sm:px-12 py-2 sm:py-3 rounded-full shadow-lg  transition duration-300">
        List event
      </button>
    </div>
  </div>
</div>


    </div>
  );
};


export default Second