


import React, { useState } from "react";
import bg6 from "../../assets/bg6.png";
import people from "../../assets/people.jpg";
import { baseUrl } from "../../apiservice";
import { Link } from "react-router-dom";
const Firstpage = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="relative z-20">
      {/* Background Image Section */}
      <div className="relative">
        <img
          src={people}
          alt="Event Background"
          className="w-full h-[90vh] object-cover"
        />
      </div>

      
      <div
        style={{ backgroundImage: `url(${bg6})` }}
        className="absolute inset-0 bg-cover bg-opacity-70 flex gap-3 flex-col justify-center items-center text-center px-4 sm:px-10 text-white"
      >
        <h1 className="text-2xl sm:text-5xl font-bold mb-4 w-11/12 mx-auto">
        Discover Events, Anywhere, Anytime!
        </h1>
        <p className="text-[10px] sm:text-base md:text-lg mb-6 w-9/12 mx-auto">
        Join a vibrant world of events curated just for you.
        Let’s celebrate life together on Eventlane.
        </p>
        <Link to='/event'  className="block no-underline text-white">
        <button className="z-50 px-10 sm:px-15 md:px-20 py-3 bg-[#f5167e] rounded-[2rem] text-sm sm:text-base md:text-2xl font-medium transition tracking-extra">
          Explore  All  Events
        </button>
       </Link>

      </div>
      
    </div>
  );
};

export default Firstpage;
