import React, { useEffect, useState } from 'react'

import img from "../../assets/Logo.png";
import logo from "../../assets/Logo.png";
import img1 from "../../assets/navcolor.png";
import img2 from "../../assets/nav.png";
import bg1 from "../../assets/bg1.png";
import bg2 from "../../assets/bg2.png";
import bg5 from "../../assets/bg5.png";
import footer from "../../assets/footer.png";
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../../components/common/Navbar';



const Thirdpage = () => {

  const location = useLocation();  // Hook to access the current location object
  const event  = location.state?.event;
  console.log(event);
  
  const [showNavbar, setShowNavbar] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      
      if (window.scrollY > 100) {
        setShowNavbar(true);
      } else {
        setShowNavbar(false);
      }
    };


    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);



    const [earlyBirdTickets, setEarlyBirdTickets] = useState(0);
    const [selectedTickets, setSelectedTickets] = useState(0);
    const [formData, setFormData] = useState({
      fullName: "",
      gender: "",
      day: "01",
      month: "Jan",
      year: "2004",
    });
    const ticketPrice = 1000;
    const generalPrice = 1500;
  
    const total = earlyBirdTickets * ticketPrice || generalPrice;
  
    const handleTicketChange = (e) => {
      setEarlyBirdTickets(parseInt(e.target.value, 10));
    };

    
   
    
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  return (
    <div>
     <Navbar/>







     
     <div className=" bg-black text-white flex items-center  bg-cover justify-center" style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}>
      <div className="w-full flex flex-col max-w-5xl mt-[7rem]  rounded-lg  px-6 py-3">
 
 <div className="  text-white flex   justify-center ">
      <div className="w-full max-w-4xl h-[15rem] sm:h-[19rem] rounded-lg sm:p-2">
        
      <div className="flex items-start   space-x-9">
          <img
            src={event.getGalleryDetail[0]['file']}
            alt="Webinar"
            className="w-[8rem] h-[9rem] sm:w-[18rem] sm:h-[16rem] rounded-lg border-3 border-white"
          />
          <div className='flex-col  justify-start h-full'>
            <p className="text-[#cccccc] text-xs sm:text-2xl mb-1">{event.category ? event.category['name'] : ''}</p>
            <span className=" text-sm sm:text-4xl ">
            {event.event_name}
            </span>
            <div className="flex flex-col items-start gap-1 sm:mt-4 sm:space-y-4">
            <div className="flex gap-2 items-center space-x-2 text-xs sm:text-2xl text-[#f5167e]">
                <i className="far fa-calendar-alt"></i>
                Sat Oct 05, 09:00 PM
              </div>
              <div className="flex gap-2 items-center space-x-2  text-xs sm:text-2xl text-white">
                <i className="fas fa-map-marker-alt"></i>
                {event.venue}
              </div>
            </div>
          </div>
        </div>

        
        



      </div>
    </div> 




<div className=" flex items-center justify-center w-full ">
          <div className="flex items-center  sm:mt-8 space-x-6 sm:space-x-9">
            <div className="flex flex-col items-center">
              <div className="w-10 h-10 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-white mt-2">Tickets</p>
            </div>
            <div className="h-1 w-8 sm:w-16 bg-[#f5167e]"></div>

            <div className="flex flex-col items-center">
            <div className="w-10 h-10 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-gray-400 mt-2">Summary</p>
            </div>
            <div className="h-1 w-8 sm:w-16 bg-[#f5167e]"></div>

            <div className="flex flex-col items-center">
            <div className="w-10 h-10 flex items-center justify-center bg-white rounded-r-lg rounded-t-lg text-white font-semibold">
                <i className="fas fa-check text-[#f5167e] "></i>
              </div>
              <p className="text-sm text-gray-400 mt-2">Payment</p>
            </div>
          </div>
        </div>





<div className="bg-[#262626] text-white sm:w-[65%] mx-auto  p-3 mt-8 rounded-3xl mb-5 text-xs sm:text-xl">

<div className="bg-[#262626] text-white p-3 rounded-lg  ">
     
      <div className="flex justify-between items-center mb-6">
        <p className="text-lg font-medium">Already have an account?</p>
        <Link to='/login'  className="block no-underline">
        <button className="text-white bg-[#f5167e] rounded-3xl py-2  px-4 font-medium">Login</button>
        </Link>
      </div>

      {/* Form */}
      <form className="space-y-4">
        {/* Full Name */}
        <div>
          <label htmlFor="fullName" className="block text-sm font-medium mb-2">
            Full Name
          </label>
          <input
            type="text"
            id="fullName"
            name="fullName"
            value={formData.fullName}
            onChange={handleInputChange}
            placeholder="Enter Your Full Name"
            className="w-full bg-black text-white p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>

        {/* Gender */}
        <div>
          <label htmlFor="gender" className="block text-sm font-medium mb-2">
            Gender
          </label>
          <select
            id="gender"
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            className="w-full bg-black text-white p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
          >
            <option value="" disabled>
              Gender
            </option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>

        {/* Date of Birth */}
        <div>
          <label className="block text-sm font-medium mb-2">Date of Birth</label>
          <div className="flex justify-around">
            <select
              name="day"
              value={formData.day}
              onChange={handleInputChange}
              className="bg-black w-[30%] text-white p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            >
              {Array.from({ length: 31 }, (_, i) => (
                <option key={i + 1} value={String(i + 1).padStart(2, "0")}>
                  {String(i + 1).padStart(2, "0")}
                </option>
              ))}
            </select>

            <select
              name="month"
              value={formData.month}
              onChange={handleInputChange}
              className="bg-black w-[30%] text-white p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            >
              {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month) => (
                <option key={month} value={month}>
                  {month}
                </option>
              ))}
            </select>

            <select
              name="year"
              value={formData.year}
              onChange={handleInputChange}
              className="bg-black w-[30%] text-white p-3 rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            >
              {Array.from({ length: 100 }, (_, i) => (
                <option key={2004 - i} value={2004 - i}>
                  {2004 - i}
                </option>
              ))}
            </select>
          </div>
        </div>
      </form>

      {/* Proceed Button */}
      
      <div className="flex justify-center items-center ">
          
  <button
    className={`mt-6 w-[70%] p-3 rounded-3xl font-medium ${
      true
        ? "bg-white text-[#f5167e]"
        : "bg-gray-300 text-gray-500 cursor-not-allowed"
    }`}
    disabled={!(true)}
  >
    Proceed
  </button>
</div>
      
    </div>

      
    </div>
      </div>
    </div>
  





{/* footer */}

<div className="relative ">
      <img src={bg5} className='absolute -top-20 h-64 w-[100vw] z-0'/>
      
      <div className="relative h-[35rem] z-10">
        
        <img
          src={footer}
          className="w-full h-[35rem] object-cover "
          alt="Background"
        />
      </div>
      <div className="absolute top-0 left-0 right-0  z-40  text-white flex flex-col justify-center items-center h-[40rem] w-full">
        <h1 className="text-[2.5rem] md:text-[3.5rem] text-center">
          Ready to Host an Event? 
        </h1>
        <h2 className="text-[1.5rem] md:text-[2rem] text-center">
          Get Your Event Live in Less Than 3 Minutes
        </h2>
        <Link to='/business'  className="block no-underline">
        <button className="z-40 text-[#f5167e] bg-white rounded-[3rem] mt-12 py-3 px-16 md:py-3 md:px-24">
          List Event
        </button>
        </Link>
      </div>


      
      <footer className="bg-[#262626] text-white py-8 md:py-16">

        
  <div className=" mx-auto h-[105vh] md:h-[47vh] ">
    
    <div className="flex-wrap md:flex md:flex-nowrap   w-[90%]  mx-auto ">

    <div className="w-full md:w-1/3 flex flex-col items-start  ">
          <div className="text-2xl font-bold mb-1 sm:mb-4 ">
            <img src={logo} alt="Eventlane Logo" className="w-3/4  md:mx-0" />
          </div>
          <p className="text-sm w-[90%] ">
          Eventlane is your one-stop platform for discovering, planning, and attending events that matter to you. We connect people with experiences they'll love!
          </p>
          <div className="flex justify-center md:justify-start mt-1 sm:mt-4 text-xl md:text-3xl space-x-4">
            <a href="https://www.facebook.com/eventlaneapp" target='blank' className="text-white ">
              <i className="fab fa-facebook" ></i>
            </a>
            <a href="https://www.instagram.com/eventlaneapp/?igsh=MWRzcWNtODRna2p5ag%3D%3D#" target='blank' className="text-white ">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/eventlane/" target='blank' className="text-white ">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
    
      <div className="flex gap-[4rem] w-full md:w-1/3">
        
        
        
        <div className=" w-1/2 flex-col justify-end items-end">
          <h3 className="text-lg font-semibold mb-4 text-start">Customers & Organisers</h3>
          <h4 className="space-y-2 text-sm"></h4>
            <div className='flex-col gap-2'>
            <p>Explore and Discover</p>
            <p>Create and Sell</p>
            <p>Manage and Invite</p>
            <p>Engage and Inspire</p>
            <p>Join and Enjoy</p>
          
            </div>
        </div>

        <div className=" w-1/2 flex-col text-start justify-center items-center">
          <h3 className="text-lg font-semibold mb-4 text-start">Eventlane</h3>
          <h4 className="space-y-2 text-sm"> </h4>
            <p>For Business</p>
            <p>Blog</p>
            <p>Contact Us</p>
            <p>Privacy Policy</p>
            <p>Terms and Conditions</p>
         
        </div>
      </div>

      
      <div className="flex  gap-[4rem] w-full md:w-1/3">
        
        

        
        <div className="w-full flex flex-col items-start">
          <h3 className="text-lg font-semibold mb-4">Never Miss an Update!</h3>
          <p className="text-sm mb-4">
          Sign up for our newsletter and be the first to know about the latest events and concerts.

          </p>
          <div className="flex items-center relative w-full">
            <div className="flex items-center border-2 border-[#f5167e] bg-white rounded-[2rem] w-full px-4 py-2">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                className="w-full bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-2"
              />
            </div>
            <button className="absolute right-2 px-4 py-2 bg-[#f5167e] rounded-[2rem] text-white ">
              Subscribe now
            </button>
          </div>
        </div>
      </div>
    </div>

   
    
  </div>
  <div className="w-[85%] mx-auto border-t border-gray-600 pt-6 text-center text-sm text-gray-400">
      Copyright © 2024 Softtura. All rights reserved.
    </div> 
</footer>

    </div>

    <div className="bg-[#262626] text-white p-2  flex justify-between mx-auto max-w-screen-full fixed bottom-0 z-40 w-full">
  <div className='flex justify-around w-full'>
  <div className="text-right flex flex-col items-start">
    <p className="text-lg">Total</p>
    <p className="text-2xl font-semibold">
      Rs. {selectedTickets * 1000 || 1500}
    </p>
  </div>

  <div>
  <Link to='/my-events'  className="block no-underline" state={{ event: event }}>
    <button
      className={`bg-[#f5167e] text-white transition-colors mt-6 py-[.60rem] px-6 rounded-3xl font-medium `}
      
    >
      Pay Now
    </button>
    </Link>
  </div>
  </div>
</div>




    </div>
  )
}

export default Thirdpage






