
import React, { useEffect, useState } from "react";
import img1 from "../../../src/assets/img.png";
import img2 from "../../../src/assets/pagefirstthird.png";
import img3 from "../../../src/assets/firstpagesecond.png";
import bg2 from "../../../src/assets/bg2.png";

import '../bussiness/second.css'
import { Link } from "react-router-dom";
import axios from "axios";
import { baseUrl } from '../../services/apiService';
const Secondpage = () => {
  const [events, setEvents] = useState([]);
  const [events1, setEvents1] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    // onSearch(event.target.value);
  };


  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}get-events?limit=8`);
      setEvents(response.data.data);
      setEvents1(response.data.data);
      setLoading(false);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  
  useEffect(() => {
    

    fetchData();
  }, [dispatchEvent]);


  




  const fetchData1 = async () => {
    try {


     
      const response = await axios.get(`${baseUrl}get-events`);
      
      
      const allEvents = response.data.data || [];

      if (searchTerm.trim() === "") {
        setEvents(allEvents);
        return;
      }
      console.log(allEvents);
      
      const filteredEvents = allEvents.filter((event) =>
        event.event_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      console.log(filteredEvents);
      
      setEvents(filteredEvents);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => { fetchData() }, [searchTerm]);


  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-white text-xl">Loading...</p>
      </div>
    );
  }

  return (
    <div className="bg-black  bg-cover w-full   text-white" style={{ backgroundImage: `url(${img2})` }}>
     
      <div className=" ">
        
      <div
  className="absolute w-full  h-[30%]  -bottom-3  sm:bottom-[3.5rem] z-20 bg-cover   "
  style={{
    backgroundImage: `url(${img3})`,
  }}
>
<div className="w-full h-full items-center flex justify-center ">
<div className="flex items-center justify-center  z-10 w-[18rem] sm:w-[20rem] lg:w-[40%] absolute top-[4rem]  mx-auto mt-3  sm:mt-10 md:mt-10">

  <div className="flex items-center  border-2 border-[#f5167e] bg-white rounded-xl overflow-hidden px-4 w-full">
    <input
      type="text"
      placeholder="Search by Events"
      value={searchTerm}
      onChange={handleInputChange}
      className="bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-[.70rem] w-full"
    />
  </div>
  
  
  <button onClick={() => fetchData1() } className="absolute  right-0 px-4 py-[.90rem] bg-[#f5167e] rounded-lg text-white hover:bg-[#e41472] transition">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-4.35-4.35M17.5 10.5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  </button>
</div>
</div>
 </div>


      </div>

      
      <section className="py-8 z-40 w-10/12   mx-auto">
        <h2 className="text-3xl font-bold text-start   sm:mt-6 md:mt-16 mb-8">
          Discover the Best of Online Events
        </h2>
        
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {events.map((event) => (
            <div className="max-w-sm mx-auto  text-white rounded-lg overflow-hidden  relative">
            {/* Image Section */}
            <div className="relative ">
            <Link  to="/event-details" state={{ event: event }}
              className="block no-underline">
              <img
                src={event.getGalleryDetail[0]['file']}
                alt="ForByStudents Logo"
                className="w-full h-72 object-cover rounded-2xl"
              />
              </Link>
              {/* Star Icon */}
              <div className="absolute top-2 right-2 bg-[#262626] text-[#f5167e] rounded-full p-2">
              <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24"
  className="w-5 h-5"
>
  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
</svg>

              </div>
            </div>
          
            {/* Badge (Line1) */}
            <div className="absolute   top-[15.5rem] left-4">
              <span className="inline-block px-3 py-1 text-xs text-white bg-[#f5167e] rounded-md">
                {event.category ? event.category['name'] : ''}
              </span>
            </div>
          
            {/* Event Details */}
            <div className="py-4  flex gap-4">
              {/* Date Section */}
              <div className="flex flex-col items-center">
                <span className="text-[#f5167e] text-lg font-bold">{ event.start_month}</span>
                <span className="text-white text-xl ">{ event.start_date}</span>
              </div>
          
              {/* Event Description */}
              <div>
                <div className="text-lg font-semibold">{event.event_name}</div>
                <div className="text-sm text-white mt-2 ">
                  <span className="font-bold">📍{event.venue}</span>
                </div>
                <div className="text-sm text-[#f5167e] mt-2">
                  <span>🕒 {event.start_time} - {event.end_time}</span>
                </div>
                <div className="text-md text-white mt-1">
               <span className='text-lg'> {event.price?'From ₹':''} <span className="font-bold ">{event.price?.price}</span>{' '}</span>
                  <span className="">
                    <span className="text-[#f5167e] text-xs">★</span> 21 Interested
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          ))}
         
        </div>
        
        <Link to='/event'  className="block no-underline">
        <button className="block text-xl mx-auto mt-8  w-[18rem] sm:w-[24rem] md:w-[28rem] px-6 py-3 bg-white rounded-full text-[#f5167e] font-bold ">
          See More
        </button>
        </Link>
      </section>

      {/* Trending Events */}
      <section className="py-8 w-10/12 mx-auto">
        <h2 className="text-3xl font-bold text-start mb-8">
          Trending Events Around the Udaipur
        </h2>
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
        {events1.map((event) => (
            <div className="max-w-sm mx-auto  text-white rounded-lg overflow-hidden  relative">
            {/* Image Section */}
            <div className="relative ">
            <Link  to="/event-details" state={{ event: event }}
              className="block no-underline">
              <img
                src={event.getGalleryDetail[0]['file']}
                alt="ForByStudents Logo"
                className="w-full h-72 object-cover rounded-2xl"
              />
              </Link>
              {/* Star Icon */}
              <div className="absolute top-2 right-2 bg-[#262626] text-[#f5167e] rounded-full p-2">
              <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24"
  className="w-5 h-5"
>
  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
</svg>

              </div>
            </div>
          
            {/* Badge (Line1) */}
            <div className="absolute   top-[15.5rem] left-4">
              <span className="inline-block px-3 py-1 text-xs text-white bg-[#f5167e] rounded-md">
                {event.category ? event.category['name'] : ''}
              </span>
            </div>
          
            {/* Event Details */}
            <div className="py-4  flex gap-4">
              {/* Date Section */}
              <div className="flex flex-col items-center">
                <span className="text-[#f5167e] text-lg font-bold">{ event.start_month}</span>
                <span className="text-white text-xl ">{ event.start_date}</span>
              </div>
          
              {/* Event Description */}
              <div>
                <div className="text-lg font-semibold">{event.event_name}</div>
                <div className="text-sm text-white mt-2 ">
                  <span className="font-bold">📍{event.venue}</span>
                </div>
                <div className="text-sm text-[#f5167e] mt-2">
                  <span>🕒 {event.start_time} - {event.end_time}</span>
                </div>
                <div className="text-md text-white mt-1">
               <span className='text-lg'> {event.price?'From ₹':''} <span className="font-bold ">{event.price?.price}</span>{' '}</span>
                  <span className="">
                    <span className="text-[#f5167e] text-xs">★</span> 21 Interested
                  </span>
                </div>
              </div>
            </div>
          </div>
          
          ))}
         
        </div>
        <Link to='/event'  className="block no-underline">
        <button className="block text-xl mx-auto mt-8  w-[18rem] sm:w-[24rem] md:w-[28rem] px-6 py-3 bg-white rounded-full text-[#f5167e] font-bold ">
          See More
        </button>
        </Link>
      </section>

      
<section className="mt-[2rem] sm:mt-52 bg-[#262626] py-1 sm:py-5">
  <div className="flex flex-row items-center justify-between w-11/12 mx-auto relative lg:py-10">
    <div className=" w-1/2 object-cover">
      <div className="w-full  z-40 absolute bottom-0 ">
        <img src={img1} alt="Create Event" className="w-[50%] h-[50%] " />
      </div>
    </div>

    <div className="py-1 flex-col mb-4 md:py-4 lg:py-8 lg2xl:py-12  w-1/2 md:text-left mt-2 sm:mt-8 md:mt-0 md:pl-12">
      <h2 className="text-[8px] sm:text-xl md:text-xl lg:text-3xl font-extrabold">
      Create & Share
      </h2>
      <p className="text-[5px] sm:text-sm md:text-md lg:text-xl mt-1   sm:mt-4 text-white">
      Design your own events and invite the world to join your journey.
      </p>
      <Link to="/business" className="no-underline">
        <button  style={{cursor:'pointer'}}
          className="cursor-pointer  z-50 absolute bottom-2 lg:bottom-6 
            mt-1 sm:mt-6 px-4 sm:px-12 md:px-16 
            py-1 sm:py-3 md:py-4 
            bg-white rounded-full 
            text-[#f5167e] text-[8px] sm:text-lg md:text-xl
          "
        >
          Create Events
        </button>
      </Link>
    </div>
  </div>
</section>

      <br/>
          <br/>
          <br/>
          
    </div>
  );
};

export default Secondpage;
