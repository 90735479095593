import React, { useState } from "react";
import dayjs from "dayjs";
import './register.css'
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../apiservice";
const RegisterModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    lastName:"",
    gender: "",
    email: "",
    dob: {
      day: "01",
      month: "Jan",
      year: "2004",
    },
  });


  const nevigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      dob: { ...formData.dob, [name]: value },
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const formattedDate = dayjs(`${formData.dob.day} ${formData.dob.month} ${formData.dob.year}`, 'DD MMM YYYY').format('YYYY-MM-DD');
      const userId = sessionStorage.getItem('id');
      const response = await axios.post(`${baseUrl}update-profile`, {
        userId,
        first_name:formData.fullName,
        last_name:formData.lastName,
        gender:formData.gender,
        email:formData.email,
        dob:formattedDate,
      });
  
     console.log(response);
     
     if(response.data.success){
      nevigate(-1);
     }else{
      alert('Email Already Exist')
     }
     
    } catch (error) {
      // Handle error response
      console.error('Error:', error.response ? error.response.data : error.message);
      alert('Failed to submit the form. Please try again.');
    }
  
    console.log(formData);
  };

  if (!isOpen) return null;
  const closeModal = () => {
    nevigate(-1);
  };

  return (
    <div onClick={closeModal} style={{ marginTop: "-1px" }}   className="fixed inset-0 z-50 flex items-center justify-center  bg-black bg-opacity-50">

      <div className="w-[45rem]  max-w-7xl rounded-3xl shadow-lg">
      <div  className=" px-3 flex items-center justify-center  " onClick={(e) => e.stopPropagation()}>
      
      <form
      className="bg-[#262626] text-white  rounded-2xl shadow-lg px-8 pt-8  pb-4 sm:py-4 w-full max-w-2xl"
      >
       <div className="text-2xl font-bold mb-3">
          <button className="text-3xl text-white" onClick={onClose}>
            ←
          </button>
          Fill Details</div>
        
        <div className="mb-2 flex flex-row gap-2">
          <div className="w-full">
          <label htmlFor="fullName" className="block mb-1">
        First Name
      </label>
          <input
            type="text"
            name="fullName"
            placeholder="Enter Your First Name"
            value={formData.fullName}
            onChange={handleInputChange}
            className="w-full p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white"
            required={true}
          />
          </div>
          <div className="w-full">
          <label htmlFor="fullName" className="block mb-1">
        Last Name
      </label>
          <input
            type="text"
            name="lastName"
            placeholder="Enter Your Last Name"
            value={formData.lastName}
            onChange={handleInputChange}
            className="w-full p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white"
          />
          </div>
          
          
        </div>
        <div className="mb-2">
        <label htmlFor="fullName" className="block mb-1">
        Gender
      </label>
          <select
            name="gender"
            value={formData.gender}
            onChange={handleInputChange}
            required={true}
            className="w-full p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white appearance-none"
            style={{ backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yMjctNC4yMjdhMSAxIDAgMCAxIDEuNDE0IDEuNDE0bC01LjY0IDUuNjRhMSAxIDAgMCAxLTEuNDE0IDBsLTUuNjQtNS42NGEuOTk5Ljk5OSAwIDAgMSAwLTEuNDE0IDEgMSAwIDAgMSAxLjQxNC0uMDA3eiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)', backgroundSize: '16px', backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat' }}
          >
            {/* <option value="">Gender</option> */}
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
        </div>
        <div className="mb-2">
        <label htmlFor="fullName" className="block mb-1">
        Email
      </label>
          <input
            type="email"
            name="email"
            placeholder="Enter Your Email"
            required={true}
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white"
          />
        </div>
        <div>
        <label htmlFor="fullName" className="block mb-1">
        Date of Birth
      </label>
      <div className="mb-6 flex space-x-2">
          
          <select
            name="day"
            value={formData.dob.day}
            required={true}
            onChange={handleDateChange}
            className="flex-1 p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white appearance-none"
            style={{ backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yMjctNC4yMjdhMSAxIDAgMCAxIDEuNDE0IDEuNDE0bC01LjY0IDUuNjRhMSAxIDAgMCAxLTEuNDE0IDBsLTUuNjQtNS42NGEuOTk5Ljk5OSAwIDAgMSAwLTEuNDE0IDEgMSAwIDAgMSAxLjQxNC0uMDA3eiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)', backgroundSize: '16px', backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat' }}
          >
            {[...Array(31).keys()].map((d) => (
              <option key={d + 1} value={String(d + 1).padStart(2, "0")}>
                {String(d + 1).padStart(2, "0")}
              </option>
            ))}
          </select>
          <select
            name="month"
            value={formData.dob.month}
            required={true}
            onChange={handleDateChange}
            className="flex-1 p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white appearance-none"
            style={{ backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yMjctNC4yMjdhMSAxIDAgMCAxIDEuNDE0IDEuNDE0bC01LjY0IDUuNjRhMSAxIDAgMCAxLTEuNDE0IDBsLTUuNjQtNS42NGEuOTk5Ljk5OSAwIDAgMSAwLTEuNDE0IDEgMSAwIDAgMSAxLjQxNC0uMDA3eiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)', backgroundSize: '16px', backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat' }}
          >
            {["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </select>
          <select
            name="year"
            value={formData.dob.year}
            required={true}
            onChange={handleDateChange}
            className="flex-1 p-3 bg-black text-white rounded-md focus:outline-none focus:ring-2 focus:ring-white appearance-none"
            style={{ backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNNC4yOTMgNi4yOTNsNC4yOTMgNC4yOTMgNC4yMjctNC4yMjdhMSAxIDAgMCAxIDEuNDE0IDEuNDE0bC01LjY0IDUuNjRhMSAxIDAgMCAxLTEuNDE0IDBsLTUuNjQtNS42NGEuOTk5Ljk5OSAwIDAgMSAwLTEuNDE0IDEgMSAwIDAgMSAxLjQxNC0uMDA3eiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==)', backgroundSize: '16px', backgroundPosition: 'right 10px center', backgroundRepeat: 'no-repeat' }}
          >
            {[...Array(100).keys()].map((y) => (
              <option key={2004 - y} value={2004 - y}>
                {2004 - y}
              </option>
            ))}
          </select>
        </div>
        </div>
        
        
       <div className="w-[80%] mx-auto">
       <button
          type="submit"
          onClick={handleSubmit}
          className="w-full p-2 mt-2 font-semibold text-xl  bg-white text-[#f5167e]  rounded-3xl  transition duration-200"
        >
          Proceed
        </button>
       </div>
      </form>
    </div>
      </div>
   
    </div>
  );
};

export default RegisterModal;




