





import React, { useEffect, useState } from 'react';
import img from '../../assets/qr.png';
import bg2 from "../../assets/bg2.png";
import bg1 from "../../assets/bg1.png";
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
const Myeventdetails = () => {
  const location = useLocation();  
const event  = location.state?.event;
const data1  = location.state?.event;
console.log('dddd',event);


  return (
    <div>
      <Navbar/>
      <div
        style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}
        className="bg-black bg-cover min-h-screen p-3   text-white"
      >
       
       <div className="bg-[#262626] mb-5 p-3 mt-[5rem] sm:p-6 rounded-lg w-11/12 md:w-10/12 mx-auto shadow-lg">
          {/* Header Section */}
          <div className=" px-3 sm:px-6 py-3 mb-6 md:mb-12 rounded-lg max-w-[82vw] mx-auto flex items-center space-x-3 sm:space-x-12 ">
      {/* Event Thumbnail */}
      <div className="w-1/4">
        <img
          src={data1.event_image}
          alt="Event Thumbnail"
          className="rounded-md border border-gray-700 h-[6rem] sm:h-[14rem]"
        />
      </div>

      {/* Event Details */}
      <div className="w-3/4">
        <h2 className=" text-[8px] sm:text-3xl font-semibold">{data1.event_name}</h2>
        <div className=" text-[6px] sm:text-sm text-gray-300 mt-2">
          <div className='text-white  text-[10px] sm:text-2xl sm:mb-3'>
            <i className="fas fa-calendar-alt mr-2 text-[#cccccc]"></i> {data1.event_time} - {data1.event_time} 
          </div>
          <div className='text-white text-[10px] sm:text-2xl sm:mb-3'>
            <i className="fas fa-map-marker-alt mr-2  text-[#cccccc]"></i>{data1.event_venue}
          </div>
          <div className='text-white  text-[10px] sm:text-2xl'>
            <span className=' text-[#cccccc]'>Booking Date:</span>  {data1?.booking_date.split('T')[0]} ({data1.booking_time.split('T')[1]} )
          </div>
        </div>
        <div className=" sm:mt-1 text-[5px] sm:text-[18px] flex justify-between items-center">
          <div className='flex  gap-2 sm:gap-4 md:space-x-6'>
            <div className="text-lg font-semibold text-white">
              <strong className='text-[6px] sm:text-2xl font-thin text-[#cccccc]'>Total Amount: <span className='text-white font-semibold text-3xl'> Rs.{data1.total}</span></strong> 
            </div>
            <div className="text-lg font-semibold text-white">
              <strong className='text-[6px] sm:text-2xl font-thin text-[#cccccc]'>Ticket:  <span className='text-white font-semibold text-3xl'>{data1.ticket}</span></strong> 
            </div>
          </div>
         <div>
         
         </div>
        </div>
      </div>
    </div>

         <div className='px-10'>
           {/* Divider */}
           <div className="border-t border-dotted border-white my-6"></div>

{/* Ticket Details */}
<div className=" md:text-base text-white text-[10px]  ">
  {/** Ticket Rows */}
  {event?.ticket_data?.map((data1, i) => (
    <div key={i} className="flex justify-between mb-4 text-[10px] sm:text-2xl">
      <div className="flex flex-col">
        <span>{data1.category}</span>
        <span className="text-gray-400 text-sm">Quantity:{data1.quantity}</span>
      </div>
      <span>₹{data1.per_ticket_price}</span>
    </div>
  ))}

 
  <div className="border-t border-dotted text-[10px] sm:text-2xl border-white mt-4 pt-2 flex justify-between text-lg font-semibold">
    <span className="text-[#cccccc] font-thin ">Total Price:</span>
    <span>₹{event?.total}</span>
  </div>
</div>

         </div>
          {/* QR Code and Transaction ID */}
          <div className="flex flex-col items-center mt-6">
            <img src={img} alt="QR Code" className="w-[25vw] h-[40vh]" />
            <p className="mt-2 text-gray-400 text-[10px] sm:text-3xl">
              Transaction ID: <strong>#Eventlane</strong>
            </p>
          </div>

          {/* Action Buttons */}
          <div className="mt-6 flex flex-col gap-3 space-x-4 justify-center">
            
          <button  className="bg-white w-[16rem] text-xl no-underline  sm:w-[32rem] mx-auto px-3 py-3 rounded-3xl text-[#f5167e] font-semibold">
          <a href={event?.pdf} className="no-underline text-[#f5167e]">
            Download Ticket
            </a>
          </button> 
        
          <button className="bg-white  px-6 py-3 w-[16rem] text-xl  sm:w-[32rem] mx-auto rounded-3xl text-[#f5167e] font-semibold">
            Share Ticket
          </button>
        </div>
        </div>
      </div>
      <Footer/>
    </div>
  );
};

export default Myeventdetails;
