


import React from 'react';
import logo from '../../assets/Logo.png';
import bg5 from "../../assets/bg5.png";
import footer from "../../assets/footer.png";
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="relative ">
      <img src={bg5} className='absolute -top-20 h-64 w-[100vw] z-0'/>
      
      <div className="relative h-[32rem] z-10">
        
        <img
          src={footer}
          className="w-full h-[32rem] object-cover "
          alt="Background"
        />
      </div>
      <div className="absolute -top-24 left-0 right-0  z-40  text-white flex flex-col justify-center items-center h-[40rem] w-full">
        <h1 className="text-[2.5rem] md:text-[3.5rem] text-center">
          Ready to Host an Event? 
        </h1>
        <div className="text-[1.5rem] md:text-[2rem] text-center">
        Get your event live with just a few clicks !
        </div>
        <Link to='/business'  className="block no-underline">
        <button className="z-40 text-[#f5167e] bg-white rounded-[3rem] mt-12 py-3 px-16 md:py-3 md:px-24">
          List Event
        </button>
        </Link>
      </div>


      
      <footer className="bg-[#262626] text-white py-8 md:py-12">

        
  <div className=" mx-auto h-[105vh] md:h-[40vh] ">
    
    <div className="flex-wrap md:flex md:flex-nowrap   w-10/12  mx-auto ">

    <div className="w-full md:w-1/3 flex flex-col items-start  ">
          <div className="text-2xl font-bold mb-1 sm:mb-4 ">
            <img src={logo} alt="Eventlane Logo" className="w-3/5  md:mx-0" />
          </div>
          <p className="text-xs w-[90%] ">
          Eventlane is your one-stop platform for discovering, planning, and attending events that matter to you. We connect people with experiences they'll love!
          </p>
          <div className="flex justify-center md:justify-start mt-1 sm:mt-4 text-xl md:text-3xl space-x-4">
            <a href="https://www.facebook.com/eventlaneapp" target='blank' className="text-white ">
              <i className="fab fa-facebook" ></i>
            </a>
            <a href="https://www.instagram.com/eventlaneapp/?igsh=MWRzcWNtODRna2p5ag%3D%3D#" target='blank' className="text-white ">
              <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.linkedin.com/company/eventlane/" target='blank' className="text-white ">
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
    
      <div className="flex gap-[4rem] w-full md:w-1/3">
        
        
        
        <div className=" w-1/2 flex-col justify-end items-end mt-2">
          <h3 className="md:text-xl text-sm font-semibold mb-4 text-start">Customers & Organisers</h3>
          <h4 className="space-y-2 md:text-sm text-[8px]"></h4>
            <div className='flex-col gap-2 md:text-sm text-[8px]'>
            <div className='mb-2'>Explore and Discover</div>
            <div className='mb-2'>Create and Sell</div>
            <div className='mb-2'>Manage and Invite</div>
            <div className='mb-2'>Engage and Inspire</div>
            <div className='mb-2'>Join and Enjoy</div>
          
            </div>
        </div>

        <div className="mt-2 w-1/2 md:text-sm text-[8px] flex-col text-start justify-center items-center">
          <h3 className="text-xl font-semibold mb-4 text-start">Eventlane</h3>
          <h4 className="space-y-2 text-sm"> </h4>
            <div className='mb-2'>For Business</div>
            <div className='mb-2'>Blog</div>
            <div className='mb-2'>Contact Us</div>
            <div className='mb-2'>Privacy Policy</div>
            <div className='mb-2'>Terms and Conditions</div>
         
        </div>
      </div>

      
      <div className="flex mt-2  gap-[4rem] w-full md:w-1/3">
        
        

        
        <div className="w-full flex flex-col items-start">
          <h3 className="text-xl font-semibold mb-4">Never Miss an Update!</h3>
          <p className="text-sm mb-4">
          Sign up for our newsletter and be the first to know about the latest events and concerts.

          </p>
          <div className="flex items-center relative w-full">
            <div className="flex items-center border-2 border-[#f5167e] bg-white rounded-[2rem] w-full px-4 py-2">
              <input
                type="text"
                placeholder="Enter Your Email Address"
                className="w-full bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none "
              />
            </div>
            <button className="absolute right-2 px-3 py-1 bg-[#f5167e] rounded-[2rem] text-white ">
              Subscribe now
            </button>
          </div>
        </div>
      </div>
    </div>

   
    
  </div>
  <div className="w-[85%] mx-auto border-t border-gray-600 pt-6 text-center text-sm text-gray-400">
      Copyright © 2024 Softtura. All rights reserved.
    </div> 
</footer>

    </div>
  );
};

export default Footer;
