import React, { useEffect, useRef, useState } from 'react'
import bg1 from '../../assets/bg1.png'
import bg2 from '../../assets/bg2.png'
import calender from '../../assets/calender.png'
import clock from '../../assets/clock.png'
import share from '../../assets/share.png'
import ev1 from '../../assets/ev1.png'
import ev2 from '../../assets/ev2.png'
import ev3 from '../../assets/ev3.png'
import ev4 from '../../assets/ev4.png'
import ev5 from '../../assets/ev5.png'
import Lightbox from './Lightbox';
import Lightbox1 from './Lightbox';
import './hello.css';
import Footer from '../../components/common/Footer';
import Navbar from '../../components/common/Navbar';
import { Link,useLocation} from 'react-router-dom';
import axios from "axios";
import { baseUrl } from '../../services/apiService';
import { FaRegCalendarAlt } from "react-icons/fa";

const Fulleventdetails = () => {
  const location = useLocation();  
const event  = location.state?.event;
const scrollRef = useRef(null);
const popupRef = useRef(null);
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDiv = () => {
    setIsDivVisible((prev) => !prev);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsDivVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  const scrollLeft = () => {
    scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' });
  };
const token = sessionStorage.getItem('token')
  const scrollRight = () => {
    scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' });
  };
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${baseUrl}get-events`);
          console.log(response);
          
          setEvents(response.data.data);
          setLoading(false);
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, [dispatchEvent]);
    
      if (loading) {
        return (
          <div className="flex justify-center items-center h-screen">
            <p className="text-white text-xl">Loading...</p>
          </div>
        );
      }


      const ll = [
        { img: ev1, name: 'Artist 1' },
        { img: ev2, name: 'Artist 2' },
        { img: ev3, name: 'Artist 3' },
        { img: ev4, name: 'Artist 4' },
      ];
      


  return (
   <>

<Navbar/>
    <div className='bg-[#262626]'  >
       <div className="flex flex-col  md:mt-3  w-[85%] mx-auto lg:flex-row justify-around items-center lg:gap-12 py-6  text-white h-[100vh] overflow-hidden -mb-20 ">
  
  <div className="w-full lg:w-2/5 flex justify-center">
    <img
      src={event.getGalleryDetail[0]['file']}
      alt="LinkedIn Webinar"
      className="w-full lg:w-[40vw] h-[35vh] lg:h-[60vh] rounded-lg border-2 border-white"
    />
  </div>


  <div className="w-full  lg:w-1/2 h-auto mm flex  flex-col justify-center">
  
    <div>
      <h5 className="text-xl lg:text-3xl lg2xl:text-5xl text-[#cccccc]">
      {event.category ? event.category['name'] : ''}
      </h5>
      <h2 className="text-base lg:text-4xl lg2xl:text-6xl font-bold mt-2">
      {event.event_name}
      </h2>
    </div>

    
    <div className="mt-1 lg:mt-4 text-base lg:text-3xl lg2xl:text-3xl text-white">
      <div className="flex items-center gap-1 lg:gap-2">
        
      <span className="flex items-center mt-2 gap-4 mb-3">
  <img className="w-[8%]" src={calender} alt="Calendar" />
  <span >Saturday, 2 December 2023</span>
</span>
     
      </div>
      <div className="flex items-center gap-1 lg:gap-2  lg:mt-2">
      <span className="flex items-center gap-4 mb-3">
  <img className="w-[10%]" src={clock} alt="Calendar" />
  <span >{event.start_time} - {event.end_time}</span>
</span>
       
      </div>
    </div>

    <div className=" lg:mt-4 flex flex-col items-center lg:items-start button ">
      <div className="flex justify-between w-full  textwhite">
        <div className="text-[#cccccc] text-base lg:text-3xl lg2xl:text-4xl">Starting From</div>
        <div className="text-white text-base lg:text-3xl font-bold lg2xl:text-4xl"> {event.price? `Rs. ${event.price.price}`:``}</div>
      </div>
      <div className='flex gap-4 w-full '>
    
     

{/* <button className="block text-xl mx-auto   w-[18rem] sm:w-[22rem] px-6 py-3 bg-white rounded-full text-[#f5167e] font-bold "> */}
<div className='w-[65%] '>
<Link to={token ? '/page-first':'/login'}  className=" no-underline " state={{ event: event }}> 
<button className=" text-xl mx-auto w-full px-6 py-3 bg-white rounded-full text-[#f5167e] font-bold ">
Get Ticket
        </button>
        </Link>
</div>


      
     {/* <div className='w-[30%] '>
     <button
  // className=" text-xl mx-auto   w-[18rem] sm:w-[12rem] px-6 py-3 bg-[#f5167e] rounded-full text-white hidden lg:block font-bold "
  className=" text-xl mx-auto  w-full  px-6 py-3 bg-[#f5167e] rounded-full text-white hidden lg:block font-bold "
>

<span className="flex items-center gap-2 text-center">

  <span className='text-2xl'>Share Event</span>
  <img className="w-[15%]" src={share} alt="Calendar" />
</span>

</button>
      </div> */}


<div className='w-[32%] hidden lg:block'>
  <button className="text-xl mx-auto w-full px-6 py-3 bg-[#f5167e] rounded-full text-white font-bold flex items-center justify-center gap-2">
    Share Events <img className="w-[15%]" src={share} alt="Share" />
  </button>
</div>
      </div>
    </div>
  </div>
</div>







    <div style={{backgroundImage: ` url(${bg2})`  }} className=" text-white bg-black bg-cover min-h-[80vh] py-3 font-sans">
      <div className="w-11/12    mx-auto space-y-8">
       


<div className=" text-white font-sans mt-5 w-11/12 mx-auto">
      <div className="   grid grid-cols-1 md:grid-cols-2 gap-16">
        
        <div className=" bg-[#262626] rounded-lg  shadow-lg  space-y-5">
          <h2 className="text-3xl font-bold px-6 pt-6">Location</h2>
          <p className="text-white px-6">
          <i className="fas fa-map-marker-alt mr-2 "></i>{ event.address}
          </p>
          <iframe
            title={ event.address}
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3153.0922172395865!2d144.9537363153188!3d-37.81720997975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce7e33!2s5th%20Ave!5e0!3m2!1sen!2sin!4v1649347658030!5m2!1sen!2sin"
            className="w-full h-[72%] object-fill rounded-lg border-0"
            
          ></iframe>
        </div>

       
        <div className="space-y-8">
{/*           
          <div className="bg-[#262626] rounded-lg shadow-lg p-3">
            <div className="flex justify-between items-center">
              <div className="flex items-center ">
                <img
                  src="https://s3-alpha-sig.figma.com/img/a511/6394/d8c24efab59533add110ee8338355c42?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YrbD-OsAYaLBS9oOptgEqmuwYzLgnONpY8RfeFrPJe8hkRoikkR20uWSdca4QVIqM4qKYzJ-FsDSj7WllWSbUQTzoyn9F-Z5rgnm~k7KYJpor05H~wt6TdWobceqK3BvpCmeb2vODeoMBTGE39v1fFr3mJteseMrFXfZhvkuvZ9cK7xQR6yCrBsQA7ZkLIJkrqmzCj0gam7-7YIqGZrcmxSJ~n6zjJ5D-nT9gcxQgX5fqiuive4mAiby6qYpZMfaPNtal8g0hNDbtCXXcgdfgQBIt~b4X2wqKNEe1YDnatEAbpr8QkjTLg6oqlUFr7Sm2H3Qtr06TLexCRR-OpALPQ__"
                  alt="Attendee 1"
                  className="w-8 h-8 z-10 relative sm:w-16 sm:h-16 rounded-full object-cover border-white border-3"
                />
                <img
                  src="https://s3-alpha-sig.figma.com/img/a511/6394/d8c24efab59533add110ee8338355c42?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YrbD-OsAYaLBS9oOptgEqmuwYzLgnONpY8RfeFrPJe8hkRoikkR20uWSdca4QVIqM4qKYzJ-FsDSj7WllWSbUQTzoyn9F-Z5rgnm~k7KYJpor05H~wt6TdWobceqK3BvpCmeb2vODeoMBTGE39v1fFr3mJteseMrFXfZhvkuvZ9cK7xQR6yCrBsQA7ZkLIJkrqmzCj0gam7-7YIqGZrcmxSJ~n6zjJ5D-nT9gcxQgX5fqiuive4mAiby6qYpZMfaPNtal8g0hNDbtCXXcgdfgQBIt~b4X2wqKNEe1YDnatEAbpr8QkjTLg6oqlUFr7Sm2H3Qtr06TLexCRR-OpALPQ__"
                  alt="Attendee 2"
                  className="w-8 h-8 absolute z-20 right-[41%] sm:w-16 sm:h-16 rounded-full object-cover border-white border-3"
                />
                <img
                  src="https://s3-alpha-sig.figma.com/img/a511/6394/d8c24efab59533add110ee8338355c42?Expires=1736726400&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=YrbD-OsAYaLBS9oOptgEqmuwYzLgnONpY8RfeFrPJe8hkRoikkR20uWSdca4QVIqM4qKYzJ-FsDSj7WllWSbUQTzoyn9F-Z5rgnm~k7KYJpor05H~wt6TdWobceqK3BvpCmeb2vODeoMBTGE39v1fFr3mJteseMrFXfZhvkuvZ9cK7xQR6yCrBsQA7ZkLIJkrqmzCj0gam7-7YIqGZrcmxSJ~n6zjJ5D-nT9gcxQgX5fqiuive4mAiby6qYpZMfaPNtal8g0hNDbtCXXcgdfgQBIt~b4X2wqKNEe1YDnatEAbpr8QkjTLg6oqlUFr7Sm2H3Qtr06TLexCRR-OpALPQ__"
                  alt="Attendee 3"
                  className="w-8 h-8 sm:w-16  absolute z-30 right-[38%] sm:h-16 rounded-full object-cover border-white border-3"
                />
                <p className="text-[#f5167e] text-xl sm:text-3xl ml-[6.5rem]  font-bold">20+ Interested</p>
              </div>
              <button className="bg-[#f5167e]  text-white py-2 px-4 rounded-full">
              View on App
              </button>
            </div>
          </div> */}




<div className="bg-[#262626] rounded-lg shadow-lg p-3 sm:p-5">
  <div className="flex flex-col sm:flex-row justify-between items-center">
    <div className="flex items-center relative w-[75%]">
      <div className="relative w-[30%]">
        <img
          src={ev1}
          alt="Attendee 1"
          className="w-10 h-10 sm:w-16 sm:h-16 rounded-full object-cover border-2 border-white"
        
        />
        <img
          src={ev2}
          alt="Attendee 2"
          className="w-10 h-10 sm:w-16 sm:h-16 rounded-full object-cover border-2 border-white absolute top-0 left-9"
        />
        <img
          src={ev3}
          alt="Attendee 3"
          className="w-10 h-10 sm:w-16 sm:h-16 rounded-full object-cover border-2 border-white absolute top-0 left-[4.5rem]"
        />
      </div>
      <div className='w-[50%]'>
      <p className="text-[#f5167e] text-lg sm:text-2xl ml-4 font-bold">20+ Interested</p>
      </div>
    </div>
    <button className="bg-[#f5167e]   text-white py-2 px-4 rounded-full">
    <a className="text-white no-underline" href="https://play.google.com/store/apps/details?id=com.event.sandesha"> View on App</a>
    </button>
  </div>
</div>


         
          <div className="bg-[#262626] rounded-lg shadow-lg px-8 py-3">
            <h2 className="text-xl sm:text-3xl font-bold mb-3">Hosted by</h2>
            <div className="flex items-center bg-[#262626]">
              <img
                 src={ev5}
                alt="Host"
                className="w-8 h-8 relative sm:w-[4.5rem] sm:h-[4.5rem]  "
              />
              <div className= 'bg-[#262626] shadow-2xl px-3 absolute right-[27%]'>
                <p className="font-bold text-lg">City Youth Movement</p>
                <div className="flex space-x-2 mt-2 ">
                  <button onClick={toggleDiv} className="bg-[#f5167e]  text-white py-1 px-3 rounded-full">
                    Contact
                  </button>

                  {isDivVisible && (
        <div ref={popupRef} className="absolute mt-2 p-6 bg-[#262626] rounded-lg w-[17rem] md:w-[25rem]">
           <div className=" text-white  rounded-lg flex flex-wrap items-center justify-between">
      
      <div className="flex items-center mb-4 md:mb-0">
        <img
          src={ev5}
          alt="City Youth Movement Logo"
          className="w-12 h-12  mr-4"
        />
        <span className="text-lg font-bold">City Youth Movement</span>
      </div>

      {/* Contact Section */}
      <div className="flex flex-col md:flex-row gap-8">
        {/* Phone */}
        <div className="flex items-center">
          <i className="fas fa-phone text-2xl mr-3"></i> {/* Add Font Awesome or use another icon library */}
          <div>
            <div className="text-sm font-semibold">PHONE</div>
            <div className="text-base">03 5432 1234</div>
          </div>
        </div>

        {/* Email */}
        <div className="flex items-center">
          <i className="fas fa-envelope text-2xl mr-3"></i> {/* Add Font Awesome or use another icon library */}
          <div>
            <div className="text-sm font-semibold">EMAIL</div>
            <div className="text-base">info@marcc.com.au</div>
          </div>
        </div>
      </div>
    </div>
        </div>
      )}
                  {/* <button className="bg-white  text-[#f5167e] py-1 px-3 rounded-full">
                    + Follow
                  </button> */}
                </div>
              </div>
            </div>
          </div>
    
<div className="col-span-3 bg-[#262626] rounded-lg shadow-lg px-6 py-3">
  <h2 className="text-xl sm:text-2xl font-bold text-white">Artists</h2>
  <div className="flex gap-4 items-center mt-4 overflow-x-auto">
    
   
   
    <div className="flex gap-8 overflow-x-auto">
    <button className="text-4xl text-white mb-[3.5rem] flex-shrink-0" onClick={() => scrollLeft()}>
      &lt;
    </button>
      {[1,2,3,4].map((artist, index) => (
        <div key={index} className="text-center ">
          {console.log(';;',artist)}
          
          <img
            src={ev3}
            alt={`Artist ${artist}`}
            className="w-[5.5rem] h-[5.5rem] rounded-full object-cover border-2 border-white"
          />
          <p className="text-white text-sm mt-2">Artist {artist}</p>
        </div>
      ))}
      
    <button className="text-4xl mb-[3.5rem] text-white flex-shrink-0" onClick={() => scrollRight()}>
      &gt;
    </button>
    </div>
 
  </div>
</div>

        </div>

      
      </div>
    </div>


 

 <section className=''>
 <h2 className="text-2xl font-bold px-16 ">Highlights</h2>
  <Lightbox  state={{ image: event.getGalleryDetail}}/>
</section>   


           
        <section className=' hello'>
          <h2 className="text-3xl font-bold">Event Description</h2>
          <p className="text-[#cccccc] mt-4">
          Get ready to kick off the Christmas season in Mumbai with SOUND OF CHRISTMAS - your favourite LIVE Christmas concert!
<br/>
<br/>
City Youth Movement invites you to the 4th edition of our annual Christmas festivities - by the youth and for the youth! Feat. your favourite worship leaders, carols, quizzes and some exciting surprises!
<br/>
<br/>
Bring your family and friends and sing along your favourite Christmas carols on the 2nd of December, 6:30 PM onwards at the Balgandharva Rang Mandir, Bandra West. Book your tickets now!
<br/>
<br/>
3 Reasons to attend the event:
<br/>
<br/>
1. The FIRST Christmas concert of Mumbai!
<br/>
2. A special Christmas Choir!
<br/>
3. Special Dance performances and many more surprises!
          </p>
        </section>

        
        <section className=' hello'>
          <h2 className="text-3xl font-bold">Terms and Conditions</h2>
          <p className="text-gray-300 mt-4">
          Get ready to kick off the Christmas season in Mumbai with SOUND OF CHRISTMAS - your favourite LIVE Christmas concert!
<br/>
<br/>
City Youth Movement invites you to the 4th edition of our annual Christmas festivities - by the youth and for the youth! Feat. your 
          </p>
        </section>

       
        <section className='hello'>
          <h2 className="text-3xl font-bold">Tags</h2>
          <div className="flex flex-wrap gap-2 mt-4">
            {["Business", "Networking", "Webinar", "Online Event"].map(
              (tag, index) => (
                <span
                  key={index}
                  className="bg-[#262626] text-gray-300 px-3 py-1 rounded-full text-sm"
                >
                  {tag}
                </span>
              )
            )}
          </div>
        </section>


        
         <section className="py-8 z-40 w-11/12   mx-auto">
         <h2 className="text-3xl px-3 font-bold ">You May Also Like</h2>
                <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4 ">
                  {events.map((event) => (
                    <div className="max-w-sm mx-auto  text-white rounded-lg overflow-hidden  relative">
                    
                    <div className="relative mt-4 ">
                    <Link  to="/event-details" state={{ event: event }}
                      className="block no-underline">
                      <img
                        src={event.getGalleryDetail[0]['file']}
                        alt="ForByStudents Logo"
                        className="w-full h-72 object-cover rounded-2xl"
                      />
                      </Link>
                     
                      <div className="absolute top-2 right-2 bg-[#262626] text-[#f5167e] rounded-full p-2">
                      <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24"
  className="w-5 h-5"
>
  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
</svg>

                      </div>
                    </div>
                  
                    
                    <div className="absolute   top-[15.5rem] left-4">
              <span className="inline-block px-3 py-1 text-xs text-white bg-[#f5167e] rounded-md">
                {event.category ? event.category['name'] : ''}
              </span>
            </div>
          
            {/* Event Details */}
            <div className="py-4  flex gap-4">
              {/* Date Section */}
              <div className="flex flex-col items-center">
                <span className="text-[#f5167e] text-lg font-bold">{ event.start_month}</span>
                <span className="text-white text-xl ">{ event.start_date}</span>
              </div>
          
              {/* Event Description */}
              <div>
                <div className="text-lg font-semibold">{event.event_name}</div>
                <div className="text-sm text-white mt-2 ">
                  <span className="font-bold">📍{event.venue}</span>
                </div>
                <div className="text-sm text-[#f5167e] mt-2">
                  <span>🕒 {event.start_time} - {event.end_time}</span>
                </div>
                <div className="text-md text-white mt-1">
               <span className='text-lg'> {event.price?'From ₹':''} <span className="font-bold ">{event.price?.price}</span>{' '}</span>
                  <span className="">
                    <span className="text-[#f5167e] text-xs">★</span> 21 Interested
                  </span>
                </div>
              </div>
            </div>
          </div>
                  
                  ))}
                 
                </div>
                
               
              </section> 

      </div>
    </div>
    </div>
    <Footer/>
   </>
  )
}

export default Fulleventdetails