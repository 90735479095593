// import React, { useState } from "react";
// import Lightbox from "react-image-lightbox";
// import "react-image-lightbox/style.css";
// import './hello.css'
// import { useLocation} from 'react-router-dom';

// const Lightbox1 = ({state}) => {

//   const [isOpen, setIsOpen] = useState(false);
//   const [photoIndex, setPhotoIndex] = useState(0);
//   const images = state.image;
//   console.log('55555',images)

//   return (
//     <div>
//       <section className=" yello ">
//         <div className="grid grid-cols-4 gap-x-4 gap-y-8 py-4">
//           {
//           images.map((image, index) => (
//             <div
//                key={index}
//                className={`${
//                 index === 0 || index === 5 ? "col-span-2" : ""
//                }`}
//             >
//               <img
//                 src={images}
//                 alt={`Image ${index + 1}`}
//                 className="w-full h-[15rem] rounded-lg cursor-pointer col-span-2 object-cover"
//                 onClick={() => {
//                    setPhotoIndex(index);
//                   setIsOpen(true);
//                 }}
//               />
//             </div>
//           ))
          
//           }
//            {images.map((image, index) => (
//             <div
//               key={index}
//               className={`${
//                 index === 0 || index === 5 ? "col-span-2" : ""
//               }`}
//             >
//               <img
//                 src={image.file}
//                 alt={`Image ${index + 1}`}
//                 className="w-full h-[15rem] rounded-lg cursor-pointer object-cover"
//                 onClick={() => {
//                   setPhotoIndex(index);
//                   setIsOpen(true);
//                 }}
//               />
//             </div>
//           ))} 
//         </div>
//       </section>
//       {isOpen  && (
        
        
//         <Lightbox
        
//           mainSrc={images[photoIndex]}
//           nextSrc={images[(photoIndex + 1) % images.length]}
//           prevSrc={
//             images[(photoIndex + images.length - 1) % images.length]
//           }
//           onCloseRequest={() => setIsOpen(false)}
//           onMovePrevRequest={() =>
//             setPhotoIndex(
//               (photoIndex + images.length - 1) % images.length
//             )
//           }
//           onMoveNextRequest={() =>
//             setPhotoIndex((photoIndex + 1) % images.length)
//           }
//         />
//       )}

//     </div>
//   );
// };



// export default Lightbox1









import React, { useState } from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import './hello.css'
import { useLocation} from 'react-router-dom';
const Lightbox1 = ({state}) => {
    
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const images = state.image;
  console.log('55555',images);
  

  return (
    <div>
      <section className=" yello ">
        <div className="grid grid-cols-4 gap-x-4 gap-y-8 py-4 ">
          {images.map((image, index) => (
            <div
              key={index}
              className={`${
                index === 0 || index === 5 ? "col-span-2" : ""
              }`}
            >
              <img
                src={image.file}
                alt={`Image ${index + 1}`}
                className="w-full h-[15rem] rounded-lg cursor-pointer"
                onClick={() => {
                  setPhotoIndex(index);
                  setIsOpen(true);
                }}
              />
            </div>
          ))}
        </div>
      </section>
      {isOpen  && (
        
        
        <Lightbox
        
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length]
          }
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + images.length - 1) % images.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}

    </div>
  );
};



export default Lightbox1