import React, { useEffect, useState } from 'react'
import bg2 from "../../assets/bg2.png";
import bg1 from "../../assets/bg1.png";
import big from "../../assets/big.png";
import Navbar from '../../components/common/Navbar';
import Footer from '../../components/common/Footer';
import { Link, useNavigate } from 'react-router-dom';
import axios  from 'axios';
import { baseUrl } from '../../apiservice';



const Myevents = () => {


  const [data, setdata] = useState(null)

  const nevigate = useNavigate()
  useEffect(() =>{
 
    const userId = sessionStorage.getItem('id')
    if(!userId){
      nevigate('/login')
    }
    const fatchdata = async () =>{
     
    try {
      const response = await axios.post(`${baseUrl}booking-Listing`,{
        userId
      })
      console.log(response.data.data);
      
      setdata(response.data.data)
    } catch (error) {
      console.log(error.message);
      
    }
    }

    fatchdata()
    
  },[])
  return (
    <div>
      <Navbar/>
         <div style={{ backgroundImage: `url(${bg1}), url(${bg2})` }} className="bg-black bg-cover text-white min-h-screen ">

      <div className="relative">
        <img
          src={big}
          alt="Event"
          className="w-[100vw] h-[40rem] hidden lg:block"
        />
        
      </div>

     
      <div style={{ backgroundImage: `url(${bg1}), url(${bg2})` }} className="bg-black bg-cover py-5 min-h-screen  text-white p-8">
      
      <div className="flex justify-center space-x-8  text-md sm:text-xl mb-8">
        <button className="bg-black text-white px-4 py-2 rounded-lg focus:ring-2 focus:ring-purple-500">
          Upcoming events
        </button>
        <button className="text-gray-400  px-4 py-2">
          Past events
        </button>
      </div>
{
  data ? <>{
    data.map((data1) =>(
      <div className="bg-[#262626] px-3 sm:px-6 py-3 mb-6 md:mb-12 rounded-lg max-w-[82vw] mx-auto flex items-center space-x-3 sm:space-x-12 shadow-lg">
      {/* Event Thumbnail */}
      <div className="w-1/4">
        <img
          src={data1.event_image}
          alt="Event Thumbnail"
          className="rounded-md border border-gray-700 h-[6rem] sm:h-[14rem]"
        />
      </div>

      {/* Event Details */}
      <div className="w-3/4">
        <h2 className=" text-[8px] sm:text-3xl font-semibold">{data1.event_name}</h2>
        <div className=" text-[6px] sm:text-sm text-gray-300 mt-2">
          <div className='text-white  text-[10px] sm:text-2xl sm:mb-3'>
            <i className="fas fa-calendar-alt mr-2 text-[#cccccc]"></i> {data1.event_time} - {data1.event_time} 
          </div>
          <div className='text-white text-[10px] sm:text-2xl sm:mb-3'>
            <i className="fas fa-map-marker-alt mr-2  text-[#cccccc]"></i>{data1.event_venue}
          </div>
          <div className='text-white  text-[10px] sm:text-2xl'>
            <span className=' text-[#cccccc]'>Booking Date:</span>  {data1?.booking_date.split('T')[0]} ({data1.booking_time.split('T')[1]} )
          </div>
        </div>
        <div className=" sm:mt-1 text-[5px] sm:text-[18px] flex justify-between items-center">
          <div className='flex  gap-2 sm:gap-4 md:space-x-6'>
            <div className="text-lg font-semibold text-white">
              <strong className='text-[6px] sm:text-2xl font-thin text-[#cccccc]'>Total Amount: <span className='text-white font-semibold text-3xl'> Rs.{data1.total}</span></strong> 
            </div>
            <div className="text-lg font-semibold text-white">
              <strong className='text-[6px] sm:text-2xl font-thin text-[#cccccc]'>Ticket:  <span className='text-white font-semibold text-3xl'>{data1.ticket}</span></strong> 
            </div>
          </div>
         <div>
         <Link to='/my-event-details'  className="block no-underline" state={{event:data1}}>
          <button  className="bg-[#f5167e]  text-white font-semibold px-4 py-2 md:px-6 sm:py-2 rounded-3xl">
            View Details
          </button>
          </Link>
         </div>
        </div>
      </div>
    </div>
    ))
  }</>:<>no events found</>
}
      
    </div>
    </div>
    <Footer/>
    </div>
  )
}

export default Myevents