import React, { useEffect, useState } from "react";
import img from "../../assets/google.png";
import img1 from "../../assets/bgshado2.png";
import img2 from "../../assets/bg.png";
import mobile1 from "../../assets/mobile1.png";
import google from "../../assets/google.png";
import { Link } from 'react-router-dom';
import event from "../../../src/assets/event.png";
import art from "../../../src/assets/art.png";
import work from "../../../src/assets/work.png";
import comady from "../../../src/assets/comady.png";
import music from "../../../src/assets/music.png";
import danse from "../../../src/assets/danse.png";
import party from "../../../src/assets/party.png";
import aniversry from "../../../src/assets/aniversry.png";
import online from "../../../src/assets/online.png";
import test1 from "../../../src/assets/test1.png";
import test2 from "../../../src/assets/test2.png";
import test3 from "../../../src/assets/text3.png";
import lt1 from "../../../src/assets/lt1.png";
import lt2 from "../../../src/assets/lt2.png";
import lt3 from "../../../src/assets/lt3.png";
const Thirdpage = () => {
  const testimonials = [
    {
      id: 1,
      text: "I’ve been using this web hosting service for over a year and I’m really impressed with the uptime and support. The website has never gone down and the customer service is always quick to help with any issues I have. Highly recommend!",
      name: "Jane Smith",
      role: "Freelance Designer",
      rating: 5,
      url:test1
    },
    {
      id: 2,
      text: "I’ve been using this web hosting service for a few months now and overall it’s been fine. The uptime has been good and I haven’t had any major issues. The pricing is also reasonable. Nothing particularly stands out as exceptional, but it gets the job done.",
      name: "Tom Williams",
      role: "Software Developer",
      rating: 4,
      url: test2
    },
    {
      id: 3,
      text: "I’ve been using this web hosting service for a few months and it’s been nothing but problems. My website has gone down multiple times and the customer service has been unresponsive. I would not recommend this company.",
      name: "Michael Brown",
      role: "Online Entrepreneur",
      rating: 2,
      url: test3
    },
  ];


  const im = [{img:lt1},{img:lt2},{img:lt3}]
      const [current, setCurrent] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2000);
    return () => clearInterval(interval);
  }, [current]);

  const handleNext = () => {
    setCurrent((prev) => (prev + 1) % imgDAta.length);
  };

  const handlePrev = () => {
    setCurrent((prev) => (prev - 1 < 0 ? imgDAta.length - 1 : prev - 1));
  };

  const getTransformStyle = (position) => {
    switch (position) {
      case 0: // Leftmost
        return { scale: 0.7, zIndex: 1 };
      case 1: // Second from left
        return { scale: 0.85, zIndex: 3 };
      case 2: // Center (biggest)
        return { scale: 1.2, zIndex: 5 };
      case 3: // Second from right
        return { scale: 0.85, zIndex: 3 };
      case 4: // Rightmost
        return { scale: 0.7, zIndex: 1 };
      default:
        return { scale: 0.5, zIndex: 0 };
    }
  };

  const getVisibleImages = () => {
    const visible = [];
    for (let i = -2; i <= 2; i++) {
      visible.push((current + i + imgDAta.length) % imgDAta.length);
    }
    return visible;
  };

  const imgDAta = [
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    {
      url: mobile1,
    },
    
  ];

  const imgDAta1 = [
    {
      text: "Music",
      url: music
    },
    {
      text: "Art",
      url: art
    },
    {
      text: "Comedy",
      url: comady
    },
    {
      text: "Workshop",
      url: work
    },
    {
      text: "Dance",
      url: danse
    },
    {
      text: "Party",
      url: party
    },
    {
      text: "Anniversary",
      url: aniversry
    },
    {
      text: "Online",
      url: online
    },
  ];

  const [slider, setSlider] = useState(0);
  const [transitioning, setTransitioning] = useState(true);

  useEffect(() => {

    const sliderInterval = setInterval(() => {
      if (slider === imgDAta.length - 1) {
        setTransitioning(false);
      }
      setSlider((prevSlider) =>
        prevSlider === imgDAta.length - 4 ? 0 : prevSlider + 1
      );
    }, 2000);

    return () => clearInterval(sliderInterval);
  }, [slider]);

 
  useEffect(() => {
    if (slider === 0) {
      setTransitioning(true);
    }
  }, [slider]);

  return (
    <div
      className="bg-black text-white font-sans bg-cover"
      style={{ backgroundImage: `url(${img1})` }}
    >
        <section className="text-center py-1">
          <h1 className="text-4xl font-bold">Discover the Eventlane App!</h1>
          <p className="text-gray-400 mt-4 max-w-xl mx-auto">
          Your ultimate event companion, now available on Google Play and the App Store.
          </p>

          <div className="w-full flex justify-center items-center py-8 relative">
            <div className="relative w-full sm:w-[90%] md:w-[80%] lg:w-[80%] pt-32 h-[600px] overflow-hidden flex justify-center items-center">
              {getVisibleImages().map((index, position) => {
                const { scale, opacity, zIndex } = getTransformStyle(position);

                return (
                  <div
                    key={index}
                    className="absolute h-full transition-transform duration-1000 ease-in-out"
                    style={{
                      transform: `translateX(${
                        (position - 2) * 120
                      }%) scale(${scale})`,
                      zIndex,
                      opacity,
                    }}
                  >
                    <img
                      src={imgDAta[index].url}
                      alt={`Slide ${index + 1}`}
                      className="w-60 h-fit object-cover"
                    />
                    
                  </div>
                );
              })}
              
            </div>
            
          </div>
          <div className="flex justify-center items-center gap-4">
          <img src={google} alt="Google Play" className="w-80 h-12 " />
          </div>
        </section>
      


        {/* <section className="py-12 relative  mx-auto">
 
  <div className=" mx-auto">
    <div className="mx-auto">
    <h2 className="text-3xl text-white z-40 w-11/12 sm:ml-32  font-bold text-start mb-8">
      Explore Categories
    </h2>
    </div >

   
    <div className="relative">
      <div className="absolute w-full h-full bg-black opacity-30"></div>
      <img
        src={event}
        alt="Explore Categories"
        className="h-[20rem] sm:h-[27rem] md:h-[20rem] lg:h-[15rem] w-full object-cover border-1 border-white"
      />
    </div>
  </div>

 
  <div className="flex z-40  w-full lg2xl:w-11/12  flex-wrap justify-center items-center absolute top-[10rem] gap-12">
    {imgDAta1.map((category, index) => (
      <div
        key={index}
        className="text-center flex flex-col items-center"
      >
        <img
          src={category.url}
          alt={category.text}
          className="w-[3rem] h-[3rem] sm:w-24 sm:h-24 lg:w-28 lg:h-28 rounded-full object-cover border-2 border-white z-20"
        />
        <p className="mt-2 text-white text-sm md:text-base lg:text-lg">
          {category.text}
        </p>
      </div>
    ))}
  </div>
</section> */}



<section className="py-12 relative  mx-auto">
 
  <div className="">
    <div className=" mx-auto">
    <h2 className="text-3xl text-white z-40 w-11/12 sm:ml-32  font-bold text-start mb-8">
      Explore Categories
    </h2>
    </div >

   
    <div className="relative">
      <div className="absolute inset-0 bg-black opacity-30"></div>
      <img
        src={event}
        alt="Explore Categories"
        className="h-[20rem] sm:h-[27rem]   lg:h-[15rem] w-full object-cover border-1 border-white"
      />
    </div>
  </div>

  <div className="absolute inset-x-0 top-[10rem] sm:top-[12rem] lg:top-[10rem] ">
  <div className="flex  z-40  w-full lg2xl:w-11/12  flex-wrap justify-center   gap-[1.5rem] sm:gap-[3.5rem]">
    {imgDAta1.map((category, index) => (
      <div
        key={index}
        className="text-center flex flex-col items-center"
      >
        <img
          src={category.url}
          alt={category.text}
          className="w-[4rem] h-[4rem] sm:w-[7vw] sm:h-[15vh]  rounded-full object-cover border-2 border-white shadow-lg"
        />
        <p className="mt-2 text-white text-sm md:text-base lg:text-lg">
          {category.text}
        </p>
      </div>
    ))}
  </div>
  </div>
</section> 





      <section className="py-12">
        <div className="flex flex-col justify-start items-start w-[80%] mx-auto">
          <h2 className="text-3xl font-bold text-center">Real Stories, Real Experiences</h2>
          <p className="text-center text-gray-300 mb-12">
          Discover what makes our users love us!
          </p>
        </div>
        <div className="flex flex-wrap justify-around gap-3  w-10/12 mx-auto mt-2">
          {testimonials.map((testimonial, index) => (
         
            <div
              key={index}
              className="bg-[#262626] px-6 py-6  rounded-lg  md:max-w-[30%] text-center"
            >
                 {console.log(';;;',testimonial)}
              <p className="text-white h-[50%] leading-loose ">{testimonial.text}</p>


              <div className="flex justify-center">
                {Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <span
                      key={index}
                      className={`h-5 w-5 font-bold ${
                        index < testimonial.rating
                          ? "text-yellow-400"
                          : "text-gray-500"
                      }`}
                    >
                      ★
                    </span>
                  ))}
              </div>
              <div
                className="w-12 mt-3 h-12 rounded-full bg-gray-600 mx-auto bg-cover"
                style={{ backgroundImage: `url(${testimonial.url})` }}
              ></div>

              
              <div className="mt-4">
                <strong className="block text-white">
                  {" "}
                  {testimonial.name}
                </strong>
                <span className="text-sm text-gray-400">
                  {" "}
                  {testimonial.role}
                </span>
              </div>
            </div>
          ))}
        </div>
      </section>

      <footer
        className="bg-gray-800 py-20 bg-cover w-full  border-y-[1px] border-white"
        style={{ backgroundImage: `url(${img2})` }}
      >
        <div className="flex justify-around gap-1 md:gap-2  text-center text-white">
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl font-bold ">151+</h3>
            <p className="text-sm sm:text-xl ">Thrilled Partygoers and Counting!</p>
          </div>
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl  font-bold ">100+</h3>
            <p className="text-sm sm:text-xl">Cities Lighting Up with Events!</p>
          </div>
          <div className="mr-5">
            <h3 className="text-base sm:text-4xl  font-bold ">10K+</h3>
            <p className="text-sm sm:text-xl">Unforgettable Events Organized!</p>
          </div>
          <div>
            <h3 className="text-base sm:text-4xl  font-bold ">2500+</h3>
            <p className="text-sm sm:text-2xl">Trusted Partners and Growing!</p>
          </div>
        </div>
      </footer>

      <section className="py-24">
        <h2 className="text-3xl font-bold text-start  w-[80%] mx-auto">
          Latest Posts
        </h2>
        <div className="flex flex-wrap w-10/12 mx-auto justify-center gap-[3.2rem] mt-8">
          {im.map((post, index) => (
            <div className="max-w-[23rem] md:w-[30%]   text-white rounded-lg overflow-hidden">
             
              <img
                src={post.img}
                alt="Event"
                className="w-[100%] h-[16rem] rounded-2xl "
              />

              {/* Content */}
              <div className="py-4">
                {/* Tag */}
                <span className="bg-[#262626] text-sm text-[#f5167e] uppercase font-semibold px-3 py-1 rounded-md">
                  Events
                </span>

                {/* Title */}
                <h2 className="text-lg font-bold mt-3">
                  Lorem ipsum is a placeholder text commonly used to demonstrate
                  the visual form.
                </h2>

                {/* Author and Date */}
                <div className="flex items-center justify-between mt-4">
                  <div className="flex items-center space-x-2">
                    <img
                      src={post.img1}
                      alt="Author"
                      className="w-8 h-8 rounded-full"
                    />
                    <span className="text-sm text-[#97989f]">
                      Tracey Wilson
                    </span>
                  </div>
                  <span className="text-sm text-[#97989f]">
                    August 20, 2022
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default Thirdpage;
