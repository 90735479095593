import React from "react";
import img from "../../assets/Logo.png";
import img1 from "../../assets/navcolor.png";
import img2 from "../../assets/nav.png";
import { Link } from "react-router-dom";


const Navbar1 = () => {
  const token = sessionStorage.getItem('token')
  return (
    <div
      style={{ backgroundImage: `url(${img1})` }}
      className="flex h-[3.2rem] md:h-[5.5rem] sm:h-[4.2rem] z-50 bg-cover items-center fixed top-0 w-full justify-center overflow-x-hidden transition-all duration-0"
    >
      <div className="flex w-10/12  items-center sm:justify-between">
        {/* Logo Section */}
        <nav className="flex justify-center items-center">
        <Link to='/'  className="block no-underline">
          <div className="flex   sm:gap-x-6 text-richblack-25">
            <img src={img} width={"75%"} className="z-10" />
          </div>
      </Link>
        </nav>

        {/* Right Section */}
        <div className="flex gap-x-2 sm:gap-x-10 items-center">
          <Link to="/business" className="block no-underline">
          <button className="border border-white bg-richblack-800 px-4 sm:px-6 md:px-8 py-[6px] sm:py-2 md:py-[6px] text-[6px] sm:text-sm md:text-base text-white rounded-full whitespace-nowrap">
  List an Event
</button>

          </Link>
          {
                       token && token.length > 2 ? "": ( <Link to='/login'  className="block no-underline">
                        <div className="w-12 sm:w-16 cursor-pointer">
                          <img src={img2} alt="Menu Icon" className="w-full" />
                        </div>
                        </Link>)
                      
                    }
        </div>
      </div>
    </div>
  );
};

export default Navbar1;
