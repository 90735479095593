import React, { useEffect, useRef, useState } from 'react'
import bg1 from '../../assets/bg1.png'
import bg2 from '../../assets/bg2.png'
import bg7 from '../../assets/bg7.png'
import { Link } from 'react-router-dom';
import axios from "axios";
import { baseUrl } from '../../services/apiService';
import { DatePicker } from "react-date-picker";
const Secondpage = () => {

    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    
  const [searchTerm, setSearchTerm] = useState('');
  // const [date, setDate] = useState(new Date());
  const [location, setLocation] = useState("");
  const [price, setPrice] = useState("");
  // const [organizer, setOrganizer] = useState("");
  const [type, setType] = useState("");
  const [activeFilter, setActiveFilter] = useState(null);
  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
    
  };


  const popupRef = useRef(null);
  const [isDivVisible, setIsDivVisible] = useState(false);

  const toggleDiv = () => {
    setIsDivVisible((prev) => !prev);
  };



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsDivVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}get-events`);
      setEvents(response.data.data);
      setLoading(false);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  useEffect(() => {
    
    fetchData();
  }, [dispatchEvent]);


  const fetchData1 = async () => {
    try {


     
      const response = await axios.get(`${baseUrl}get-events`);
      
      
      const allEvents = response.data.data || [];

      if (searchTerm.trim() === "") {
        setEvents(allEvents);
        return;
      }
      console.log(allEvents);
      
      const filteredEvents = allEvents.filter((event) =>
        event.event_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
      console.log(filteredEvents);
      
      setEvents(filteredEvents);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => { fetchData() }, [searchTerm]);

  
  const toggleFilter = (filter) => {
    setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
  };


    
      if (loading) {
        return (
          <div className="flex justify-center items-center h-screen">
            <p className="text-white text-xl">Loading...</p>
          </div>
        );
      }

  return (
    <div >
      {/* <img src={bg7} className='z-10 absolute top-[12rem] sm:top-[18rem] h-40 lg:h-80 w-[100vw] md:top-[25rem] lg:top-[28rem]' /> */}

      <img src={bg7} className='z-10 absolute h-[20rem] w-[100vw] -bottom-8  ' />
      
         <div   style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}
 className=" bg-cover bg-black  py-6  ">
  <div className="w-full h-full items-center flex justify-center ">
<div className="flex items-center justify-center gap-2   z-40 w-[18rem] sm:w-[20rem] lg:w-[50%] absolute bottom-4 mx-auto mt-3   sm:mt-10 md:mt-10">
  {/* Input Field */}
  <div className="flex items-center  border-2 border-[#f5167e] bg-white rounded-xl overflow-hidden px-4 w-full">
    <input
      type="text"
      placeholder="Search by Events"
      value={searchTerm}
      onChange={handleInputChange}
      className="bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-3 w-full"
    />
  </div>
  
  {/* Search Button */}
  <button onClick={() => fetchData1() } className="absolute right-[4.8rem] px-4 py-[1.2rem] bg-[#f5167e] rounded-lg text-white hover:bg-[#e41472] transition">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className="w-5 h-5"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-4.35-4.35M17.5 10.5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  </button>

  <div className=''>
<button onClick={toggleDiv} className="z-40 px-4 py-[1.2rem] bg-[#f5167e] rounded-lg text-white hover:bg-[#e41472] transition">
<svg 
    viewBox="0 0 24 24" 
    version="1.1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlnsXlink="http://www.w3.org/1999/xlink" 
    fill="#ffffff" 
    className="w-5 h-5"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <title>Filter</title>
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Filter">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24"></rect>
          <line x1="4" y1="5" x2="16" y2="5" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
          <line x1="4" y1="12" x2="10" y2="12" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
          <line x1="14" y1="12" x2="20" y2="12" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
          <line x1="8" y1="19" x2="20" y2="19" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
          <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="18" cy="5" r="2"></circle>
          <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="12" cy="12" r="2"></circle>
          <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="6" cy="19" r="2"></circle>
        </g>
      </g>
    </g>
  </svg>
</button>

{isDivVisible && (
        <div ref={popupRef} className="absolute right-0  mt-2 p-4 bg-[#262626] rounded-lg w-[17rem] md:w-[18rem]">
           <div className="bg-[#262626] text-white   rounded-lg">
      {/* Location Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilter("location")}
          className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
        >
          <div className='space-y-1'>
          <span className="flex items-center">
            📍 Location

           
          </span>
          {/* <span className='text-[.60rem]'>y78</span> */}
          </div>
          
          <span>{activeFilter === "location" ? "▲" : "▼"}</span>
        </button>
       
        {activeFilter === "location" && (
          <input
            type="text"
            placeholder="Search by city"
            value={location}
            onChange={(e) => setLocation(e.target.value)}
            className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
          />
        )}
      </div>

      {/* Price Filter */}
      <div className="mb-4">
        <button
          onClick={() => toggleFilter("price")}
          className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
        >
          <span className="flex items-center">
            💲 Price
          </span>
          <span>{activeFilter === "price" ? "▲" : "▼"}</span>
        </button>
        {activeFilter === "price" && (
          <select
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
          >
            <option value="">Select</option>
            <option value="$">$</option>
            <option value="$$">$$</option>
            <option value="$$$">$$$</option>
          </select>
        )}
      </div>

     
      <div className="mb-2">
        <button
          onClick={() => toggleFilter("type")}
          className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
        >
          <span className="flex items-center">
            🔖 Catagory
          </span>
          <span>{activeFilter === "type" ? "▲" : "▼"}</span>
        </button>
        {activeFilter === "type" && (
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
          >
            <option value="">Select Any</option>
            <option value="Type 1">Type 1</option>
            <option value="Type 2">Type 2</option>
          </select>
        )}
      </div>
    </div>
        </div>
      )}
</div>

</div>

</div>
       
<section className="py-8 z-40 w-10/12   mx-auto">
        
        <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
          {events.map((event) => (
            <div className="max-w-sm mx-auto  text-white rounded-lg overflow-hidden  relative">
            {/* Image Section */}
            <div className="relative ">
            <Link  to="/event-details" state={{ event: event }}
              className="block no-underline">
              <img
                src={event.getGalleryDetail[0]['file']}
                alt="ForByStudents Logo"
                className="w-full h-72 object-cover rounded-2xl"
              />
              </Link>
              {/* Star Icon */}
              <div className="absolute top-2 right-2 bg-[#262626] text-[#f5167e] rounded-full p-2">
              <svg
  xmlns="http://www.w3.org/2000/svg"
  fill="currentColor"
  viewBox="0 0 24 24"
  className="w-5 h-5"
>
  <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
</svg>

              </div>
            </div>
          
            {/* Badge (Line1) */}
            <div className="absolute   top-[15.5rem] left-4">
              <span className="inline-block px-3 py-1 text-xs text-white bg-[#f5167e] rounded-md">
                {event.category ? event.category['name'] : ''}
              </span>
            </div>
          
            {/* Event Details */}
            <div className="py-4  flex gap-4">
              {/* Date Section */}
              <div className="flex flex-col items-center">
                <span className="text-[#f5167e] text-lg font-bold">{ event.start_month}</span>
                <span className="text-white text-xl ">{ event.start_date}</span>
              </div>
          
              {/* Event Description */}
              <div>
                <div className="text-lg font-semibold">{event.event_name}</div>
                <div className="text-sm text-white mt-2 ">
                  <span className="font-bold">📍{event.venue}</span>
                </div>
                <div className="text-sm text-[#f5167e] mt-2">
                  <span>🕒 {event.start_time} - {event.end_time}</span>
                </div>
                <div className="text-md text-white mt-1">
               <span className='text-lg'> {event.price?'From ₹':''} <span className="font-bold ">{event.price?.price}</span>{' '}</span>
                  <span className="">
                    <span className="text-[#f5167e] text-xs">★</span> 21 Interested
                  </span>
                </div>
              </div>
            </div>
          </div>
          ))}
         
        </div>
        
       
      </section>
       
    
        </div>
    </div>
  )
}

export default Secondpage



 {/* Date Filter */}
      {/* <div className="mb-4">
        <button
          onClick={() => toggleFilter("date")}
          className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
        >
          <span className="flex items-center">
            📅 Date
          </span>
          <span>{activeFilter === "date" ? "▲" : "▼"}</span>
        </button>
        {activeFilter === "date" && (
          <DatePicker
            onChange={setDate}
            value={date}
            className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
            format="dd-MM-y"
          />
        )}
      </div> */}

      {/* Organizer Filter */}
      {/* <div className="mb-4">
        <button
          onClick={() => toggleFilter("organizer")}
          className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
        >
          <span className="flex items-center">
            👤 Organizer
          </span>
          <span>{activeFilter === "organizer" ? "▲" : "▼"}</span>
        </button>
        {activeFilter === "organizer" && (
          <select
            value={organizer}
            onChange={(e) => setOrganizer(e.target.value)}
            className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
          >
            <option value="">Select Any</option>
            <option value="Organizer 1">Organizer 1</option>
            <option value="Organizer 2">Organizer 2</option>
          </select>
        )}
      </div> */}

      {/* Type Filter */}





// const Secondpage = () => {
//   const popupRef = useRef(null);
//     const [events, setEvents] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [isDivVisible, setIsDivVisible] = useState(false);
//   const [searchTerm, setSearchTerm] = useState('');
//   // const [date, setDate] = useState(new Date());
//   const [location, setLocation] = useState("");
//   const [price, setPrice] = useState("");
//   // const [organizer, setOrganizer] = useState("");
//   const [type, setType] = useState("");
//   const [activeFilter, setActiveFilter] = useState(null);
//   const handleInputChange = (event) => {
//     setSearchTerm(event.target.value);
    
//   };
//   const toggleDiv = () => {
//     setIsDivVisible((prev) => !prev);
//   };
//   const toggleFilter = (filter) => {
//     setActiveFilter((prevFilter) => (prevFilter === filter ? null : filter));
//   };









//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       if (popupRef.current && !popupRef.current.contains(event.target)) {
//         setIsDivVisible(false);
//       }
//     };

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   const fetchData = async () => {
//     try {
//       const response = await axios.get(`${baseUrl}get-events`);
//       setEvents(response.data.data);
//       setLoading(false);
      
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };


//   useEffect(() => {
    
//     fetchData();
//   }, [dispatchEvent]);


//   const fetchData1 = async () => {
//     try {


     
//       const response = await axios.get(`${baseUrl}get-events`);
      
      
//       const allEvents = response.data.data || [];

//       if (searchTerm.trim() === "") {
//         setEvents(allEvents);
//         return;
//       }
//       console.log(allEvents);
      
//       const filteredEvents = allEvents.filter((event) =>
//         event.event_name.toLowerCase().includes(searchTerm.toLowerCase())
//       )
//       console.log(filteredEvents);
      
//       setEvents(filteredEvents);
      
//     } catch (error) {
//       console.error("Error fetching data:", error);
//     }
//   };

//   useEffect(() => { fetchData() }, [searchTerm]);

//   useEffect(() => {
//     setEvents(filterData());
//     console.log('filterData',filterData());
    
//   },[setLocation, setPrice, setType]);


//   const filterData = () => {
//     let filteredEvents = [...events];


//     if (location.trim()) {
//       filteredEvents = filteredEvents.filter((event) =>
//         event.venue?.toLowerCase().includes(location.toLowerCase())
//       );
//     }

//     if (price) {
//       filteredEvents = filteredEvents.filter((event) => event.price?.price === price);
//     }

//     if (type) {
//       filteredEvents = filteredEvents.filter((event) => event?.category['name'] === type);
//     }

//     return filteredEvents;
//   };

  

    
//       if (loading) {
//         return (
//           <div className="flex justify-center items-center h-screen">
//             <p className="text-white text-xl">Loading...</p>
//           </div>
//         );
//       }

      
       
       

//   return (
//     <div >
//       {/* <img src={bg7} className='z-10 absolute top-[12rem] sm:top-[18rem] h-40 lg:h-80 w-[100vw] md:top-[25rem] lg:top-[28rem]' /> */}

//       <img src={bg7} className='z-10 absolute h-64 w-[100vw] -bottom-3  ' />
      
//          <div   style={{ backgroundImage: `url(${bg1}), url(${bg2})` }}
//  className=" bg-cover bg-black  py-6  ">
//   <div className="w-full h-full items-center flex justify-center ">
// <div className="flex items-center justify-center gap-2   z-40 w-[18rem] sm:w-[20rem] lg:w-[40%] absolute bottom-10 mx-auto mt-3  sm:mt-10 md:mt-10">
//   {/* Input Field */}
//   <div className="flex items-center  border-2 border-[#f5167e] bg-white rounded-xl overflow-hidden px-4 w-full">
//     <input
//       type="text"
//       placeholder="Search by Events"
//       value={searchTerm}
//       onChange={handleInputChange}
//       className="bg-transparent text-gray-700 placeholder-gray-500 focus:outline-none py-3 w-full"
//     />
//   </div>
  
//   {/* Search Button */}
//   <button onClick={() => fetchData1() } className="absolute right-[4.8rem] px-4 py-[1.2rem] bg-[#f5167e] rounded-lg text-white hover:bg-[#e41472] transition">
//     <svg
//       xmlns="http://www.w3.org/2000/svg"
//       fill="none"
//       viewBox="0 0 24 24"
//       strokeWidth={2}
//       stroke="currentColor"
//       className="w-5 h-5"
//     >
//       <path
//         strokeLinecap="round"
//         strokeLinejoin="round"
//         d="M21 21l-4.35-4.35M17.5 10.5a7 7 0 11-14 0 7 7 0 0114 0z"
//       />
//     </svg>
//   </button>

//   <div className=''>
// <button onClick={toggleDiv} className="z-40 px-4 py-[1.2rem] bg-[#f5167e] rounded-lg text-white hover:bg-[#e41472] transition">
// <svg 
//     viewBox="0 0 24 24" 
//     version="1.1" 
//     xmlns="http://www.w3.org/2000/svg" 
//     xmlnsXlink="http://www.w3.org/1999/xlink" 
//     fill="#ffffff" 
//     className="w-5 h-5"
//   >
//     <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
//     <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
//     <g id="SVGRepo_iconCarrier">
//       <title>Filter</title>
//       <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
//         <g id="Filter">
//           <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24"></rect>
//           <line x1="4" y1="5" x2="16" y2="5" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
//           <line x1="4" y1="12" x2="10" y2="12" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
//           <line x1="14" y1="12" x2="20" y2="12" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
//           <line x1="8" y1="19" x2="20" y2="19" id="Path" stroke="#ffffff" stroke-width="2" stroke-linecap="round"></line>
//           <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="18" cy="5" r="2"></circle>
//           <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="12" cy="12" r="2"></circle>
//           <circle id="Oval" stroke="#ffffff" stroke-width="2" stroke-linecap="round" cx="6" cy="19" r="2"></circle>
//         </g>
//       </g>
//     </g>
//   </svg>
// </button>

// {isDivVisible && (
//         <div ref={popupRef} className="absolute right-0  mt-2 p-4 bg-[#262626] rounded-lg w-[17rem] md:w-[18rem]">
//            <div className="bg-[#262626] text-white   rounded-lg">
//       {/* Location Filter */}
//       <div className="mb-4">
//         <button
//           onClick={() => toggleFilter("location")}
//           className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
//         >
//           <div className='space-y-1'>
//           <span className="flex items-center">
//             📍 Location

           
//           </span>
//           {/* <span className='text-[.60rem]'>y78</span> */}
//           </div>
          
//           <span>{activeFilter === "location" ? "▲" : "▼"}</span>
//         </button>
       
//         {activeFilter === "location" && (
//           <input
//             type="text"
//             placeholder="Search by city"
//             value={location}
//             onChange={(e) => setLocation(e.target.value)}
//             className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
//           />
//         )}
//       </div>

//       {/* Price Filter */}
//       <div className="mb-4">
//         <button
//           onClick={() => toggleFilter("price")}
//           className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
//         >
//           <span className="flex items-center">
//             💲 Price
//           </span>
//           <span>{activeFilter === "price" ? "▲" : "▼"}</span>
//         </button>
//         {activeFilter === "price" && (
//           <select
//             value={price}
//             onChange={(e) => setPrice(e.target.value)}
//             className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
//           >
//             <option value="">Select</option>
//             <option value="$">$</option>
//             <option value="$$">$$</option>
//             <option value="$$$">$$$</option>
//           </select>
//         )}
//       </div>

     
//       <div className="mb-2">
//         <button
//           onClick={() => toggleFilter("type")}
//           className="flex justify-between items-center w-full text-sm font-semibold focus:outline-none"
//         >
//           <span className="flex items-center">
//             🔖 Catagory
//           </span>
//           <span>{activeFilter === "type" ? "▲" : "▼"}</span>
//         </button>
//         {activeFilter === "type" && (
//           <select
//             value={type}
//             onChange={(e) => setType(e.target.value)}
//             className="w-full mt-2 p-2 rounded bg-gray-700 text-sm focus:outline-none"
//           >
//             <option value="">Select Any</option>
//             <option value="Type 1">Type 1</option>
//             <option value="Type 2">Type 2</option>
//           </select>
//         )}
//       </div>
//     </div>
//         </div>
//       )}
// </div>

// </div>

// </div>
       
// <section className="py-8 z-40 w-11/12  px-6 mx-auto">
        
//         <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-4">
//           {events.map((event) => (
//             <div className="max-w-sm mx-auto  text-white rounded-lg overflow-hidden  relative">
//             {/* Image Section */}
//             <div className="relative ">
//             <Link  to="/event-details" state={{ event: event }}
//               className="block no-underline">
//               <img
//                 src={event.getGalleryDetail[0]['file']}
//                 alt="ForByStudents Logo"
//                 className="w-full h-72 object-cover rounded-2xl"
//               />
//               </Link>
//               {/* Star Icon */}
//               <div className="absolute top-2 right-2 bg-[#262626] text-[#f5167e] rounded-full p-2">
//               <svg
//   xmlns="http://www.w3.org/2000/svg"
//   fill="currentColor"
//   viewBox="0 0 24 24"
//   className="w-5 h-5"
// >
//   <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
// </svg>

//               </div>
//             </div>
          
//             {/* Badge (Line1) */}
//             <div className="absolute   top-[15rem] left-4">
//               <span className="inline-block px-3 py-1 text-sm text-white bg-[#f5167e] rounded-md">
//                 {event.category ? event.category['name'] : ''}
//               </span>
//             </div>
          
//             {/* Event Details */}
//             <div className="py-4  flex gap-4">
//               {/* Date Section */}
//               <div className="flex flex-col items-center">
//                 <span className="text-[#f5167e] text-lg font-bold">{ event.start_month}</span>
//                 <span className="text-white text-2xl font-bold">{ event.start_date}</span>
//               </div>
          
//               {/* Event Description */}
//               <div>
//                 <p className="text-lg font-semibold">{event.event_name}</p>
//                 <p className="text-sm text-white mt-1">
//                   <span className="font-bold">📍{event.venue}</span>
//                 </p>
//                 <p className="text-sm text-[#f5167e] mt-1">
//                   <span>🕒 {event.start_time} - {event.end_time}</span>
//                 </p>
//                 <p className="text-md text-white mt-1">
//                 {event.price?'From ':''} <span className="font-bold">{event.price?.price}</span>{' '}
//                   <span className="ml-2">
//                     <span className="text-[#f5167e]">★</span> 21 Interested
//                   </span>
//                 </p>
//               </div>
//             </div>
//           </div>
          
//           ))}
         
//         </div>
        
       
//       </section>
       
    
//         </div>
//     </div>
//   )
// }

// export default Secondpage

