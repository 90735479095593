



// import React, { useEffect, useState } from "react";
// import OtpInput from "react-otp-input";
// import bg4 from "../../assets/bg4.png";
// import Navbar from "../../components/common/Navbar";
// import Footer from "../../components/common/Footer";
// import { Link, useNavigate } from "react-router-dom";
// import RegisterModal from "./Register";

// const Phonelogin = ({ isOpen, onClose, setIsOpen }) => {
//   const navigate = useNavigate();
//   const [phoneNumber, setPhoneNumber] = useState("");
//   const [otp, setOtp] = useState(new Array(4).fill(""));
//   const [timer, setTimer] = useState(59);
//   const [receiveUpdates, setReceiveUpdates] = useState(false);
//   const [isPopupOpen, setIsPopupOpen] = useState(false);
//   const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);

//   // Handle OTP input change
//   const handleOtpChange = (element, index) => {
//     if (isNaN(element.value)) return;
//     const newOtp = [...otp];
//     newOtp[index] = element.value;
//     setOtp(newOtp);

//     // Focus next input
//     if (element.nextSibling) element.nextSibling.focus();
//   };

//   const openRegisterModal = () => {


//     // timeee();
//     setIsRegisterModalOpen(true); 
   
//   };

//   // const timeee = () => {
//   //   const interval = setInterval(() => {
//   //     if (timer > 0) {
//   //       setTimer((prev) => prev - 1);
//   //     }
//   //   }, 1000);

//   //   return () => clearInterval(interval);
//   // }

//   // Resend Timer
//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (timer > 0) {
//         setTimer((prev) => prev - 1);
//       }
//     }, 1000);

//     return () => clearInterval(interval);
//   }, [timer]);

//   const handleProceed = () => {
//     // alert(`Phone: ${phoneNumber}, OTP: ${otp.join("")}`);
//   };


//   if (!isOpen) return null;

//   const onback  = () => {
//     navigate('/login');
//   };

//   const closeRegisterModal = () => {
//     setIsRegisterModalOpen(false); 
    
//   };

//   return (
//     <div style={{ marginTop: "-1px" }} className="fixed inset-0 px-3 z-50  bg-black bg-opacity-50 flex items-center justify-center">
//       <div className="w-full bg-[#262626] px-8 py-8 sm:py-12 rounded-2xl shadow-lg max-w-2xl relative">
//         {/* Close Button */}
//         {/* <button
//           onClick={onClose}
//           className="absolute top-4 right-4 text-white text-xl"
//         >
//           &times;
//         </button> */}

//         <div>
//           <div className="text-2xl  font-bold mb-4">
//             <button className="text-base  sm:text-2xl text-white" onClick={onClose}>
//               ←  Enter Mobile Number and OTP
//             </button>
            
//           </div>
//           <form
//   onSubmit={(e) => {
//     e.preventDefault();
//     openRegisterModal()
//   }}
  
// >
//           <div className="space-y-4">
//             <div>
//               <label className="block text-sm mb-2">Phone Number</label>
//               <div className="flex items-center gap-2">
//                 <select className="bg-black border border-gray-600 rounded px-3 py-2 text-white text-sm">
//                   <option value="+91">IN +91</option>
//                 </select>
//                 <input
//                   type="number"
//                   minLength={10}
//                   maxLength={10}
//                   placeholder="Please enter your Number"
//                   className="flex-grow bg-black border border-white rounded px-1 sm:px-3 py-2 text-white text-sm"
//                   value={phoneNumber}
//                   onChange={(e) => setPhoneNumber(e.target.value)}
                  
//                 />
//               </div>
//             </div>

//             <div>
//               <p className="text-sm text-white">
//                 An OTP has been sent to{" "}
//                 <span className="text-white">
//                   {phoneNumber || "+91-0000000000"}
//                 </span>
//               </p>
//               <label className="block text-sm mb-2 mt-4">Enter the OTP</label>
//               <div className="flex justify-between gap-2">
//                 <OtpInput
//                   value={otp}
//                   onChange={setOtp}
//                   numInputs={6}
//                   // required = {true}
//                   renderInput={(props, index) => (
//                     <input
//                       maxLength={1}
//                       // required = {true}
//                       onChange={(e) => {
//                         const { value } = e.target;
//                         const newOtp = [...otp];

//                         if (/^[0-9]$/.test(value)) {
//                           newOtp[index] = value;
//                           setOtp(newOtp);

//                           if (e.target.nextSibling) {
//                             e.target.nextSibling.focus();
//                           }
//                         } else if (value === "") {
//                           newOtp[index] = "";
//                           setOtp(newOtp);
//                         }
//                       }}
//                       style={{
//                         boxShadow:
//                           "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
//                       }}
//                       className="bg-[#262626] w-[40px] h-[40px] sm:w-[90px] sm:h-[40px] border-b-2 bg-richblack-800 text-white text-center focus:border-0 focus:outline-2 focus:outline-yellow-50"
//                     />
//                   )}
//                   containerStyle={{
//                     justifyContent: "space-between",
//                     gap: "0 6px",
//                   }}
//                 />
//               </div>
//               <div className="flex justify-between">
//                 <p className="mt-2 text-sm text-white">
//                   Resend in{" "}
//                   <span className="text-white">
//                     00:{timer < 10 ? `0${timer}` : timer}
//                   </span>
//                 </p>
//                 <div className="flex items-center gap-3">
//                   <div
//                     className="relative inline-block w-11 h-6 cursor-pointer"
//                     onClick={() => setReceiveUpdates(!receiveUpdates)}
//                   >
//                     <input
//                       id="switch"
//                       type="checkbox"
//                       checked={receiveUpdates}
//                       onChange={() => {}}
//                       className="sr-only"
//                     />
//                     <div
//                       className={`w-full h-full rounded-full transition duration-200 ${
//                         receiveUpdates ? "bg-[#f5167e]" : "bg-gray-700"
//                       }`}
//                     ></div>
//                     <div
//                       className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 transform ${
//                         receiveUpdates ? "translate-x-5" : ""
//                       }`}
//                     ></div>
//                   </div>
//                   <label
//                     htmlFor="switch"
//                     className="text-[11px] sm:text-md text-gray-400"
//                   >
//                     Receive Updates via WhatsApp
//                   </label>
//                 </div>
//               </div>
//             </div>
//           </div>

         
//           <div className="w-[80%] mx-auto">
          
//           <button
           
//               type="submit"
//                 className="w-full bg-white text-[#f5167e] rounded-[2rem] text-lg font-semibold py-3 mt-6  transition duration-200"
                
//               >
//                 Proceed
//               </button>
//               <RegisterModal isOpen={isRegisterModalOpen} onClose={closeRegisterModal} />
//           </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Phonelogin;






























import React, { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import bg4 from "../../assets/bg4.png";
import Navbar from "../../components/common/Navbar";
import Footer from "../../components/common/Footer";
import { Link, useNavigate } from "react-router-dom";
import RegisterModal from "./Register";
import axios from "axios";
import {baseUrl} from '../.././apiservice'
import './register.css'
const Phonelogin = ({ isOpen, onClose, setIsOpen }) => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [timer, setTimer] = useState(300);
  const [receiveUpdates, setReceiveUpdates] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [otpresponse, setotpresponse] = useState(false);
  const [otptoken, setotptoken] = useState(false);
  const [verifyresponse, setverifyresponse] = useState(false);
  // Handle OTP input change
  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;
    const newOtp = [...otp];
    newOtp[index] = element.value;
    setOtp(newOtp);

    // Focus next input
    if (element.nextSibling) element.nextSibling.focus();
  };

  const startTimer = () => {
    setIsTimerRunning(true);
  };

  useEffect(() => {
    let interval;
      
    if (isTimerRunning) {
      interval = setInterval(() => {
        setTimer((prev) => (prev > 0 ? prev - 1 : 0));
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [isTimerRunning]);

  const openRegisterModal = () => {


    // timeee();
    setIsRegisterModalOpen(true); 
   
  };

  // const timeee = () => {
  //   const interval = setInterval(() => {
  //     if (timer > 0) {
  //       setTimer((prev) => prev - 1);
  //     }
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }

  // Resend Timer
 

  const handleProceed = () => {
    // alert(`Phone: ${phoneNumber}, OTP: ${otp.join("")}`);
  };


  if (!isOpen) return null;

  const onback  = () => {
    navigate('/login');
  };

  const closeRegisterModal = () => {
    setIsRegisterModalOpen(false); 
    
  };

  const closeModal = () => {
    navigate(-1);
  };

  const handalsendotp = async () => {
    try {
      const response = await axios.post(`${baseUrl}send-otp`, {
        phone_no: phoneNumber,
      });
      if (response) {
        setotpresponse(response.data);
        console.log(response);
        alert('An OTP has been sent to your mobile number')
      } else {
        alert("Failed to send OTP.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
    startTimer();
  };



  const handalverify = async () => {
  console.log(phoneNumber,otpresponse.token, otp.join(""));
  

   const userOtp = otp.join("");
    try {
      const response = await axios.post(`${baseUrl}otp-verification`, {
        phone_no: phoneNumber,
        type:'0',
        token:otpresponse.token,
        otp:userOtp

      });
      console.log(response);
      
      if (response.data.token) {
        sessionStorage.setItem("token", response.data.token);
        sessionStorage.setItem("id", response.data?.user?.id);
        console.log(response);
        setverifyresponse(response);
        if(response.data.is_new_user){
          openRegisterModal()

        }else{
          navigate(-1);
        }
        openRegisterModal()
        console.log(response);
      } else {
        alert("Not a Valid OTP");
      }
    } catch (error) {
      console.error("Error in Login", error);
    }
    startTimer();
  };


  return (
    <div onClick={closeModal} style={{ marginTop: "-1px" }} className="fixed inset-0 px-3 z-50  bg-black bg-opacity-50 flex items-center justify-center">
      <div className="w-full bg-[#262626] px-8 py-8 sm:py-12 rounded-2xl shadow-lg max-w-2xl relative">
        {/* Close Button */}
        {/* <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-xl"
        >
          &times;
        </button> */}

        <div onClick={(e) => e.stopPropagation()}>
          <div className="text-2xl  font-bold mb-4">
            <button className="text-base  sm:text-3xl text-white" onClick={onClose}>
              <span className=" text-[40px]">← </span> Enter Mobile Number and OTP
            </button>
            
          </div>
          <form
  onSubmit={(e) => {
    e.preventDefault();
  
  }}
  
>
          <div className="space-y-4">
            <div>
              <label className="block text-md mb-2">Phone Number</label>
              <div className="flex items-center gap-2">
              <div className="bg-black border  border-gray-600 rounded px-3 py-[1.2rem] text-white text-xl">
                  <div value="+91">IN +91</div>
                </div>
                <input
                 
                  type="number"
                   inputMode="numeric"
                   pattern="[0-9]*"
                  minLength={10}
                  maxLength={10}
                  placeholder="Please enter your Number"
                  className="flex-grow bg-black border  placeholder:text-lg border-white rounded px-1 sm:px-3 py-[1.2rem] text-white text-xl"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required = {true}
                  style={{ textIndent: '1.5rem' }} 
                />


                <button onClick={handalsendotp} disabled={phoneNumber.length !== 10} className="w-[4.5rem] bg-white text-[#f5167e] rounded-md text-sm font-semibold py-[1.5rem]  px-1  transition duration-200">
                  Send OTP
                </button>
              </div>
            </div>

            
          </div>

          </form>


          <form 
  onSubmit={(e) => {
    e.preventDefault();
  
  }}
  
>
         
         <div>
         <div>
              {/* <p className="text-sm text-white">
                An OTP has been sent to{" "}
                <span className="text-white">
                  {phoneNumber || "+91-0000000000"}
                </span>
              </p> */}
              <label className="block text-md mb-2 mt-4">Enter the OTP</label>
              <div className="flex justify-between gap-2">
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={5}
                   required = {true}
                  renderInput={(props, index) => (
                    <input
                      maxLength={1}
                       required = {true}
                      onChange={(e) => {
                        const { value } = e.target;
                        const newOtp = [...otp];
                        if (index === 0 || otp[index - 1] !== "") {
                        if (/^[0-9]$/.test(value)) {
                          newOtp[index] = value;
                          setOtp(newOtp);

                          if (e.target.nextSibling) {
                            e.target.nextSibling.focus();
                          }
                        } else if (value === "") {
                          newOtp[index] = "";
                          setOtp(newOtp);
                        }
                       
                      }}
                    }

                      onKeyDown={(e) => {
                        const { value } = e.target;
              
                        // Handle backspace to move to the previous input
                        if (e.key === "Backspace" && !value && e.target.previousSibling) {
                          const newOtp = [...otp];
                          newOtp[index] = "";
                          setOtp(newOtp);
                          e.target.previousSibling.focus();
                        }
                      }}
                      style={{
                        boxShadow:
                          "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                      }}
                      className="bg-[#262626] w-[50px] h-[40px] sm:w-[100px] sm:h-[40px] border-b-2 bg-richblack-800 text-white text-center focus:border-0 focus:outline-2 focus:outline-yellow-50"
                    />
                  )}
                  containerStyle={{
                    justifyContent: "space-between",
                    gap: "0 25px",
                  }}
                />
              </div>
              <div className="flex justify-between">
                <p className="mt-3 text-md text-white">
                  Resend in{" "}
                  <span className="text-white">
                  {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                  </span>
                </p>
                <div className="flex items-center gap-3">
                  <div
                    className="relative inline-block w-11 h-6 cursor-pointer"
                    onClick={() => setReceiveUpdates(!receiveUpdates)}
                  >
                    <input
                      id="switch"
                      type="checkbox"
                      checked={receiveUpdates}
                      onChange={() => {}}
                      className="sr-only"
                    />
                    <div
                      className={`w-full h-full rounded-full transition duration-200 ${
                        receiveUpdates ? "bg-[#f5167e]" : "bg-gray-700"
                      }`}
                    ></div>
                    <div
                      className={`absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition-transform duration-200 transform ${
                        receiveUpdates ? "translate-x-5" : ""
                      }`}
                    ></div>
                  </div>
                  <label
                    htmlFor="switch"
                    className="text-[11px] sm:text-lg text-gray-400"
                  >
                    Receive Updates via WhatsApp
                  </label>
                </div>
              </div>
            </div>


         <div className="w-[80%] mx-auto">
          
          <button
           
              onClick={handalverify}
                className="w-full bg-white text-[#f5167e] rounded-[2rem] text-2xl font-semibold py-3 mt-6  transition duration-200"
                
              >
                Proceed
              </button>
              <RegisterModal isOpen={isRegisterModalOpen} onClose={closeRegisterModal} />
          </div>
         </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Phonelogin;



























